@charset "UTF-8";
@font-face {
  font-family: "font-icons";
  src: url("fonts/font-icons.eot");
  src: url("fonts/font-icons.eot") format("embedded-opentype"), url("fonts/font-icons.woff") format("woff"), url("fonts/font-icons.ttf") format("truetype"), url("fonts/font-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lined-icons";
  src: url("fonts/lined-icons.eot");
  src: url("fonts/lined-icons.eot") format("embedded-opentype"), url("fonts/lined-icons.woff") format("woff"), url("fonts/lined-icons.ttf") format("truetype"), url("fonts/lined-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Simple-Line-Icons";
  src: url("fonts/Simple-Line-Icons.eot");
  src: url("fonts/Simple-Line-Icons.eot?#iefix") format("embedded-opentype"), url("fonts/Simple-Line-Icons.woff") format("woff"), url("fonts/Simple-Line-Icons.ttf") format("truetype"), url("fonts/Simple-Line-Icons.svg#Simple-Line-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-],
[class*=" icon-"],
[class^=icon-line-],
[class*=" icon-line-"],
[class^=icon-line2-],
[class*=" icon-line2-"],
.side-header .primary-menu ul > li.sub-menu > a:after,
.docs-navigation ul ul li.current a:after {
  display: inline-block;
  font-family: "font-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-line-],
[class*=" icon-line-"] {
  font-family: "lined-icons";
}

[class^=icon-line2-],
[class*=" icon-line2-"] {
  font-family: "Simple-Line-Icons";
}

.icon-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-fw {
  width: 1.2857142857em;
  text-align: center;
}

.iconlist {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none;
}

.iconlist li {
  position: relative;
  display: flex;
  align-items: baseline;
}

.iconlist ul {
  list-style-type: none;
  margin: 0.5rem 0 0.5rem 1.5rem;
}

.iconlist > li [class^=icon-]:first-child,
.iconlist > li [class*=" icon-"]:first-child {
  position: relative;
  text-align: center;
  top: 1px;
  width: 1rem;
  margin-right: 0.75rem;
  flex: 0 0 auto;
}

.rtl .iconlist > li [class^=icon-]:first-child,
.rtl .iconlist > li [class*=" icon-"]:first-child {
  margin-left: 0.75rem;
  margin-right: 0;
}

.iconlist.iconlist-large {
  font-size: 1.5rem;
}

.iconlist.iconlist-large li {
  margin: 4px 0;
}

.iconlist.iconlist-large > li [class^=icon-],
.iconlist.iconlist-large > li [class*=" icon-"] {
  width: 1.5rem;
  margin-right: 5px;
}

.rtl .iconlist.iconlist-large > li [class^=icon-],
.rtl .iconlist.iconlist-large > li [class*=" icon-"] {
  margin-left: 5px;
  margin-right: 0;
}

.iconlist > li [class^=icon-]:first-child {
  color: #1ABC9C;
  color: var(--themecolor);
}

.icon-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eeeeee;
  border-radius: 0.1em;
}

.icon.pull-left {
  margin-right: 0.3em;
}

.icon.pull-right {
  margin-left: 0.3em;
}

.icon-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-stacked {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.icon-stacked-1x,
.icon-stacked-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.rtl .icon-stacked-1x,
.rtl .icon-stacked-2x {
  left: auto;
  right: 0;
}

.icon-stacked-1x {
  line-height: inherit;
}

.icon-stacked-2x {
  font-size: 2em;
}

.icon-inverse {
  color: #ffffff;
}

.icon-type:before {
  content: "";
}

.icon-box1:before {
  content: "";
}

.icon-archive1:before {
  content: "";
}

.icon-envelope2:before {
  content: "";
}

.icon-email:before {
  content: "";
}

.icon-files:before {
  content: "";
}

.icon-printer2:before {
  content: "";
}

.icon-folder-add:before {
  content: "";
}

.icon-folder-settings:before {
  content: "";
}

.icon-folder-check:before {
  content: "";
}

.icon-wifi-low:before {
  content: "";
}

.icon-wifi-mid:before {
  content: "";
}

.icon-wifi-full:before {
  content: "";
}

.icon-connection-empty:before {
  content: "";
}

.icon-battery-full1:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-arrow-left1:before {
  content: "";
}

.icon-arrow-up1:before {
  content: "";
}

.icon-arrow-down1:before {
  content: "";
}

.icon-arrow-right1:before {
  content: "";
}

.icon-reload:before {
  content: "";
}

.icon-download1:before {
  content: "";
}

.icon-tag1:before {
  content: "";
}

.icon-trashcan:before {
  content: "";
}

.icon-search1:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-clock2:before {
  content: "";
}

.icon-printer:before {
  content: "";
}

.icon-home1:before {
  content: "";
}

.icon-flag2:before {
  content: "";
}

.icon-meter:before {
  content: "";
}

.icon-switch:before {
  content: "";
}

.icon-forbidden:before {
  content: "";
}

.icon-phone-landscape:before {
  content: "";
}

.icon-tablet1:before {
  content: "";
}

.icon-tablet-landscape:before {
  content: "";
}

.icon-laptop1:before {
  content: "";
}

.icon-camera1:before {
  content: "";
}

.icon-microwave-oven:before {
  content: "";
}

.icon-credit-cards:before {
  content: "";
}

.icon-map-marker1:before {
  content: "";
}

.icon-map2:before {
  content: "";
}

.icon-support:before {
  content: "";
}

.icon-newspaper2:before {
  content: "";
}

.icon-barbell:before {
  content: "";
}

.icon-stopwatch1:before {
  content: "";
}

.icon-atom1:before {
  content: "";
}

.icon-image2:before {
  content: "";
}

.icon-cube1:before {
  content: "";
}

.icon-bars1:before {
  content: "";
}

.icon-chart:before {
  content: "";
}

.icon-pencil:before {
  content: "";
}

.icon-measure:before {
  content: "";
}

.icon-eyedropper:before {
  content: "";
}

.icon-file-settings:before {
  content: "";
}

.icon-file-add:before {
  content: "";
}

.icon-file2:before {
  content: "";
}

.icon-align-left1:before {
  content: "";
}

.icon-align-right1:before {
  content: "";
}

.icon-align-center1:before {
  content: "";
}

.icon-align-justify1:before {
  content: "";
}

.icon-file-broken:before {
  content: "";
}

.icon-browser:before {
  content: "";
}

.icon-windows1:before {
  content: "";
}

.icon-window:before {
  content: "";
}

.icon-folder2:before {
  content: "";
}

.icon-connection-25:before {
  content: "";
}

.icon-connection-50:before {
  content: "";
}

.icon-connection-75:before {
  content: "";
}

.icon-connection-full:before {
  content: "";
}

.icon-list1:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-stack3:before {
  content: "";
}

.icon-battery-charging:before {
  content: "";
}

.icon-battery-empty1:before {
  content: "";
}

.icon-battery-25:before {
  content: "";
}

.icon-battery-50:before {
  content: "";
}

.icon-battery-75:before {
  content: "";
}

.icon-refresh:before {
  content: "";
}

.icon-volume:before {
  content: "";
}

.icon-volume-increase:before {
  content: "";
}

.icon-volume-decrease:before {
  content: "";
}

.icon-mute:before {
  content: "";
}

.icon-microphone1:before {
  content: "";
}

.icon-microphone-off:before {
  content: "";
}

.icon-book1:before {
  content: "";
}

.icon-checkmark:before {
  content: "";
}

.icon-checkbox-checked:before {
  content: "";
}

.icon-checkbox:before {
  content: "";
}

.icon-paperclip1:before {
  content: "";
}

.icon-chat-1:before {
  content: "";
}

.icon-chat-2:before {
  content: "";
}

.icon-chat-3:before {
  content: "";
}

.icon-comment2:before {
  content: "";
}

.icon-calendar2:before {
  content: "";
}

.icon-bookmark2:before {
  content: "";
}

.icon-email2:before {
  content: "";
}

.icon-heart2:before {
  content: "";
}

.icon-enter:before {
  content: "";
}

.icon-cloud1:before {
  content: "";
}

.icon-book2:before {
  content: "";
}

.icon-star2:before {
  content: "";
}

.icon-lock1:before {
  content: "";
}

.icon-unlocked:before {
  content: "";
}

.icon-unlocked2:before {
  content: "";
}

.icon-users1:before {
  content: "";
}

.icon-user2:before {
  content: "";
}

.icon-users2:before {
  content: "";
}

.icon-user21:before {
  content: "";
}

.icon-bullhorn1:before {
  content: "";
}

.icon-share1:before {
  content: "";
}

.icon-screen:before {
  content: "";
}

.icon-phone1:before {
  content: "";
}

.icon-phone-portrait:before {
  content: "";
}

.icon-calculator1:before {
  content: "";
}

.icon-bag:before {
  content: "";
}

.icon-diamond:before {
  content: "";
}

.icon-drink:before {
  content: "";
}

.icon-shorts:before {
  content: "";
}

.icon-vcard:before {
  content: "";
}

.icon-sun2:before {
  content: "";
}

.icon-bill:before {
  content: "";
}

.icon-coffee1:before {
  content: "";
}

.icon-tv2:before {
  content: "";
}

.icon-newspaper3:before {
  content: "";
}

.icon-stack:before {
  content: "";
}

.icon-syringe1:before {
  content: "";
}

.icon-health:before {
  content: "";
}

.icon-bolt1:before {
  content: "";
}

.icon-pill:before {
  content: "";
}

.icon-bones:before {
  content: "";
}

.icon-lab:before {
  content: "";
}

.icon-clipboard2:before {
  content: "";
}

.icon-mug:before {
  content: "";
}

.icon-bucket:before {
  content: "";
}

.icon-select:before {
  content: "";
}

.icon-graph:before {
  content: "";
}

.icon-crop1:before {
  content: "";
}

.icon-heart21:before {
  content: "";
}

.icon-cloud2:before {
  content: "";
}

.icon-star21:before {
  content: "";
}

.icon-pen1:before {
  content: "";
}

.icon-diamond2:before {
  content: "";
}

.icon-display:before {
  content: "";
}

.icon-paperplane:before {
  content: "";
}

.icon-params:before {
  content: "";
}

.icon-banknote:before {
  content: "";
}

.icon-vynil:before {
  content: "";
}

.icon-truck1:before {
  content: "";
}

.icon-world:before {
  content: "";
}

.icon-tv1:before {
  content: "";
}

.icon-sound:before {
  content: "";
}

.icon-video1:before {
  content: "";
}

.icon-trash1:before {
  content: "";
}

.icon-user3:before {
  content: "";
}

.icon-key1:before {
  content: "";
}

.icon-search2:before {
  content: "";
}

.icon-settings2:before {
  content: "";
}

.icon-camera2:before {
  content: "";
}

.icon-tag2:before {
  content: "";
}

.icon-lock2:before {
  content: "";
}

.icon-bulb:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-eye2:before {
  content: "";
}

.icon-bubble:before {
  content: "";
}

.icon-stack2:before {
  content: "";
}

.icon-cup:before {
  content: "";
}

.icon-phone2:before {
  content: "";
}

.icon-news:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-like:before {
  content: "";
}

.icon-photo:before {
  content: "";
}

.icon-note:before {
  content: "";
}

.icon-clock21:before {
  content: "";
}

.icon-data:before {
  content: "";
}

.icon-music1:before {
  content: "";
}

.icon-megaphone:before {
  content: "";
}

.icon-study:before {
  content: "";
}

.icon-lab2:before {
  content: "";
}

.icon-food:before {
  content: "";
}

.icon-t-shirt:before {
  content: "";
}

.icon-fire1:before {
  content: "";
}

.icon-clip:before {
  content: "";
}

.icon-shop:before {
  content: "";
}

.icon-calendar21:before {
  content: "";
}

.icon-wallet1:before {
  content: "";
}

.icon-glass:before {
  content: "";
}

.icon-music2:before {
  content: "";
}

.icon-search3:before {
  content: "";
}

.icon-envelope21:before {
  content: "";
}

.icon-heart3:before {
  content: "";
}

.icon-star3:before {
  content: "";
}

.icon-star-empty:before {
  content: "";
}

.icon-user4:before {
  content: "";
}

.icon-film1:before {
  content: "";
}

.icon-th-large1:before {
  content: "";
}

.icon-th1:before {
  content: "";
}

.icon-th-list1:before {
  content: "";
}

.icon-ok:before {
  content: "";
}

.icon-remove:before {
  content: "";
}

.icon-zoom-in2:before {
  content: "";
}

.icon-zoom-out2:before {
  content: "";
}

.icon-off:before {
  content: "";
}

.icon-signal1:before {
  content: "";
}

.icon-cog1:before {
  content: "";
}

.icon-trash2:before {
  content: "";
}

.icon-home2:before {
  content: "";
}

.icon-file21:before {
  content: "";
}

.icon-time:before {
  content: "";
}

.icon-road1:before {
  content: "";
}

.icon-download-alt:before {
  content: "";
}

.icon-download2:before {
  content: "";
}

.icon-upload1:before {
  content: "";
}

.icon-inbox1:before {
  content: "";
}

.icon-play-circle2:before {
  content: "";
}

.icon-repeat:before {
  content: "";
}

.icon-refresh2:before {
  content: "";
}

.icon-list-alt2:before {
  content: "";
}

.icon-lock3:before {
  content: "";
}

.icon-flag21:before {
  content: "";
}

.icon-headphones1:before {
  content: "";
}

.icon-volume-off1:before {
  content: "";
}

.icon-volume-down1:before {
  content: "";
}

.icon-volume-up1:before {
  content: "";
}

.icon-qrcode1:before {
  content: "";
}

.icon-barcode1:before {
  content: "";
}

.icon-tag3:before {
  content: "";
}

.icon-tags1:before {
  content: "";
}

.icon-book3:before {
  content: "";
}

.icon-bookmark21:before {
  content: "";
}

.icon-print2:before {
  content: "";
}

.icon-camera3:before {
  content: "";
}

.icon-font1:before {
  content: "";
}

.icon-bold1:before {
  content: "";
}

.icon-italic1:before {
  content: "";
}

.icon-text-height1:before {
  content: "";
}

.icon-text-width1:before {
  content: "";
}

.icon-align-left2:before {
  content: "";
}

.icon-align-center2:before {
  content: "";
}

.icon-align-right2:before {
  content: "";
}

.icon-align-justify2:before {
  content: "";
}

.icon-list2:before {
  content: "";
}

.icon-indent-left:before {
  content: "";
}

.icon-indent-right:before {
  content: "";
}

.icon-facetime-video:before {
  content: "";
}

.icon-picture:before {
  content: "";
}

.icon-pencil2:before {
  content: "";
}

.icon-map-marker2:before {
  content: "";
}

.icon-adjust1:before {
  content: "";
}

.icon-tint1:before {
  content: "";
}

.icon-edit2:before {
  content: "";
}

.icon-share2:before {
  content: "";
}

.icon-check1:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-step-backward1:before {
  content: "";
}

.icon-fast-backward1:before {
  content: "";
}

.icon-backward1:before {
  content: "";
}

.icon-play1:before {
  content: "";
}

.icon-pause1:before {
  content: "";
}

.icon-stop1:before {
  content: "";
}

.icon-forward1:before {
  content: "";
}

.icon-fast-forward1:before {
  content: "";
}

.icon-step-forward1:before {
  content: "";
}

.icon-eject1:before {
  content: "";
}

.icon-chevron-left1:before {
  content: "";
}

.icon-chevron-right1:before {
  content: "";
}

.icon-plus-sign:before {
  content: "";
}

.icon-minus-sign:before {
  content: "";
}

.icon-remove-sign:before {
  content: "";
}

.icon-ok-sign:before {
  content: "";
}

.icon-question-sign:before {
  content: "";
}

.icon-info-sign:before {
  content: "";
}

.icon-screenshot:before {
  content: "";
}

.icon-remove-circle:before {
  content: "";
}

.icon-ok-circle:before {
  content: "";
}

.icon-ban-circle:before {
  content: "";
}

.icon-arrow-left2:before {
  content: "";
}

.icon-arrow-right2:before {
  content: "";
}

.icon-arrow-up2:before {
  content: "";
}

.icon-arrow-down2:before {
  content: "";
}

.icon-share-alt1:before {
  content: "";
}

.icon-resize-full:before {
  content: "";
}

.icon-resize-small:before {
  content: "";
}

.icon-plus1:before {
  content: "";
}

.icon-minus1:before {
  content: "";
}

.icon-asterisk1:before {
  content: "";
}

.icon-exclamation-sign:before {
  content: "";
}

.icon-gift1:before {
  content: "";
}

.icon-leaf1:before {
  content: "";
}

.icon-fire2:before {
  content: "";
}

.icon-eye-open:before {
  content: "";
}

.icon-eye-close:before {
  content: "";
}

.icon-warning-sign:before {
  content: "";
}

.icon-plane1:before {
  content: "";
}

.icon-calendar3:before {
  content: "";
}

.icon-random1:before {
  content: "";
}

.icon-comment21:before {
  content: "";
}

.icon-magnet1:before {
  content: "";
}

.icon-chevron-up1:before {
  content: "";
}

.icon-chevron-down1:before {
  content: "";
}

.icon-retweet1:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-folder-close:before {
  content: "";
}

.icon-folder-open2:before {
  content: "";
}

.icon-resize-vertical:before {
  content: "";
}

.icon-resize-horizontal:before {
  content: "";
}

.icon-bar-chart:before {
  content: "";
}

.icon-twitter-sign:before {
  content: "";
}

.icon-facebook-sign:before {
  content: "";
}

.icon-camera-retro1:before {
  content: "";
}

.icon-key2:before {
  content: "";
}

.icon-cogs1:before {
  content: "";
}

.icon-comments2:before {
  content: "";
}

.icon-thumbs-up2:before {
  content: "";
}

.icon-thumbs-down2:before {
  content: "";
}

.icon-star-half2:before {
  content: "";
}

.icon-heart-empty:before {
  content: "";
}

.icon-signout:before {
  content: "";
}

.icon-linkedin-sign:before {
  content: "";
}

.icon-pushpin:before {
  content: "";
}

.icon-external-link:before {
  content: "";
}

.icon-signin:before {
  content: "";
}

.icon-trophy1:before {
  content: "";
}

.icon-github-sign:before {
  content: "";
}

.icon-upload-alt:before {
  content: "";
}

.icon-lemon2:before {
  content: "";
}

.icon-phone3:before {
  content: "";
}

.icon-check-empty:before {
  content: "";
}

.icon-bookmark-empty:before {
  content: "";
}

.icon-phone-sign:before {
  content: "";
}

.icon-twitter2:before {
  content: "";
}

.icon-facebook2:before {
  content: "";
}

.icon-github2:before {
  content: "";
}

.icon-unlock1:before {
  content: "";
}

.icon-credit:before {
  content: "";
}

.icon-rss2:before {
  content: "";
}

.icon-hdd2:before {
  content: "";
}

.icon-bullhorn2:before {
  content: "";
}

.icon-bell2:before {
  content: "";
}

.icon-certificate1:before {
  content: "";
}

.icon-hand-right:before {
  content: "";
}

.icon-hand-left:before {
  content: "";
}

.icon-hand-up:before {
  content: "";
}

.icon-hand-down:before {
  content: "";
}

.icon-circle-arrow-left:before {
  content: "";
}

.icon-circle-arrow-right:before {
  content: "";
}

.icon-circle-arrow-up:before {
  content: "";
}

.icon-circle-arrow-down:before {
  content: "";
}

.icon-globe1:before {
  content: "";
}

.icon-wrench1:before {
  content: "";
}

.icon-tasks1:before {
  content: "";
}

.icon-filter1:before {
  content: "";
}

.icon-briefcase1:before {
  content: "";
}

.icon-fullscreen:before {
  content: "";
}

.icon-group:before {
  content: "";
}

.icon-link1:before {
  content: "";
}

.icon-cloud3:before {
  content: "";
}

.icon-beaker:before {
  content: "";
}

.icon-cut1:before {
  content: "";
}

.icon-copy2:before {
  content: "";
}

.icon-paper-clip:before {
  content: "";
}

.icon-save2:before {
  content: "";
}

.icon-sign-blank:before {
  content: "";
}

.icon-reorder:before {
  content: "";
}

.icon-list-ul1:before {
  content: "";
}

.icon-list-ol1:before {
  content: "";
}

.icon-strikethrough1:before {
  content: "";
}

.icon-underline1:before {
  content: "";
}

.icon-table1:before {
  content: "";
}

.icon-magic1:before {
  content: "";
}

.icon-truck2:before {
  content: "";
}

.icon-pinterest2:before {
  content: "";
}

.icon-pinterest-sign:before {
  content: "";
}

.icon-google-plus-sign:before {
  content: "";
}

.icon-google-plus1:before {
  content: "";
}

.icon-money:before {
  content: "";
}

.icon-caret-down1:before {
  content: "";
}

.icon-caret-up1:before {
  content: "";
}

.icon-caret-left1:before {
  content: "";
}

.icon-caret-right1:before {
  content: "";
}

.icon-columns1:before {
  content: "";
}

.icon-sort1:before {
  content: "";
}

.icon-sort-down1:before {
  content: "";
}

.icon-sort-up1:before {
  content: "";
}

.icon-envelope-alt:before {
  content: "";
}

.icon-linkedin2:before {
  content: "";
}

.icon-undo1:before {
  content: "";
}

.icon-legal:before {
  content: "";
}

.icon-dashboard:before {
  content: "";
}

.icon-comment-alt2:before {
  content: "";
}

.icon-comments-alt:before {
  content: "";
}

.icon-bolt2:before {
  content: "";
}

.icon-sitemap1:before {
  content: "";
}

.icon-umbrella1:before {
  content: "";
}

.icon-paste1:before {
  content: "";
}

.icon-lightbulb2:before {
  content: "";
}

.icon-exchange:before {
  content: "";
}

.icon-cloud-download:before {
  content: "";
}

.icon-cloud-upload:before {
  content: "";
}

.icon-user-md1:before {
  content: "";
}

.icon-stethoscope1:before {
  content: "";
}

.icon-suitcase1:before {
  content: "";
}

.icon-bell-alt:before {
  content: "";
}

.icon-coffee2:before {
  content: "";
}

.icon-food2:before {
  content: "";
}

.icon-file-alt2:before {
  content: "";
}

.icon-building2:before {
  content: "";
}

.icon-hospital2:before {
  content: "";
}

.icon-ambulance1:before {
  content: "";
}

.icon-medkit1:before {
  content: "";
}

.icon-fighter-jet1:before {
  content: "";
}

.icon-beer1:before {
  content: "";
}

.icon-h-sign:before {
  content: "";
}

.icon-plus-sign2:before {
  content: "";
}

.icon-double-angle-left:before {
  content: "";
}

.icon-double-angle-right:before {
  content: "";
}

.icon-double-angle-up:before {
  content: "";
}

.icon-double-angle-down:before {
  content: "";
}

.icon-angle-left:before {
  content: "";
}

.icon-angle-right:before {
  content: "";
}

.icon-angle-up:before {
  content: "";
}

.icon-angle-down:before {
  content: "";
}

.icon-desktop1:before {
  content: "";
}

.icon-laptop2:before {
  content: "";
}

.icon-tablet2:before {
  content: "";
}

.icon-mobile1:before {
  content: "";
}

.icon-circle-blank:before {
  content: "";
}

.icon-quote-left1:before {
  content: "";
}

.icon-quote-right1:before {
  content: "";
}

.icon-spinner1:before {
  content: "";
}

.icon-circle2:before {
  content: "";
}

.icon-reply1:before {
  content: "";
}

.icon-github-alt1:before {
  content: "";
}

.icon-folder-close-alt:before {
  content: "";
}

.icon-folder-open-alt:before {
  content: "";
}

.icon-expand-alt:before {
  content: "";
}

.icon-collapse-alt:before {
  content: "";
}

.icon-smile2:before {
  content: "";
}

.icon-frown2:before {
  content: "";
}

.icon-meh2:before {
  content: "";
}

.icon-gamepad1:before {
  content: "";
}

.icon-keyboard2:before {
  content: "";
}

.icon-flag-alt:before {
  content: "";
}

.icon-flag-checkered1:before {
  content: "";
}

.icon-terminal1:before {
  content: "";
}

.icon-code1:before {
  content: "";
}

.icon-reply-all1:before {
  content: "";
}

.icon-star-half-full:before {
  content: "";
}

.icon-location-arrow1:before {
  content: "";
}

.icon-crop2:before {
  content: "";
}

.icon-code-fork:before {
  content: "";
}

.icon-unlink1:before {
  content: "";
}

.icon-question1:before {
  content: "";
}

.icon-info1:before {
  content: "";
}

.icon-exclamation1:before {
  content: "";
}

.icon-superscript1:before {
  content: "";
}

.icon-subscript1:before {
  content: "";
}

.icon-eraser1:before {
  content: "";
}

.icon-puzzle:before {
  content: "";
}

.icon-microphone2:before {
  content: "";
}

.icon-microphone-off2:before {
  content: "";
}

.icon-shield:before {
  content: "";
}

.icon-calendar-empty:before {
  content: "";
}

.icon-fire-extinguisher1:before {
  content: "";
}

.icon-rocket1:before {
  content: "";
}

.icon-maxcdn1:before {
  content: "";
}

.icon-chevron-sign-left:before {
  content: "";
}

.icon-chevron-sign-right:before {
  content: "";
}

.icon-chevron-sign-up:before {
  content: "";
}

.icon-chevron-sign-down:before {
  content: "";
}

.icon-html52:before {
  content: "";
}

.icon-css31:before {
  content: "";
}

.icon-anchor1:before {
  content: "";
}

.icon-unlock-alt1:before {
  content: "";
}

.icon-bullseye1:before {
  content: "";
}

.icon-ellipsis-horizontal:before {
  content: "";
}

.icon-ellipsis-vertical:before {
  content: "";
}

.icon-rss-sign:before {
  content: "";
}

.icon-play-sign:before {
  content: "";
}

.icon-ticket:before {
  content: "";
}

.icon-minus-sign-alt:before {
  content: "";
}

.icon-check-minus:before {
  content: "";
}

.icon-level-up:before {
  content: "";
}

.icon-level-down:before {
  content: "";
}

.icon-check-sign:before {
  content: "";
}

.icon-edit-sign:before {
  content: "";
}

.icon-external-link-sign:before {
  content: "";
}

.icon-share-sign:before {
  content: "";
}

.icon-compass2:before {
  content: "";
}

.icon-collapse:before {
  content: "";
}

.icon-collapse-top:before {
  content: "";
}

.icon-expand1:before {
  content: "";
}

.icon-euro:before {
  content: "";
}

.icon-gbp:before {
  content: "";
}

.icon-dollar:before {
  content: "";
}

.icon-rupee:before {
  content: "";
}

.icon-yen:before {
  content: "";
}

.icon-renminbi:before {
  content: "";
}

.icon-won:before {
  content: "";
}

.icon-bitcoin2:before {
  content: "";
}

.icon-file3:before {
  content: "";
}

.icon-file-text:before {
  content: "";
}

.icon-sort-by-alphabet:before {
  content: "";
}

.icon-sort-by-alphabet-alt:before {
  content: "";
}

.icon-sort-by-attributes:before {
  content: "";
}

.icon-sort-by-attributes-alt:before {
  content: "";
}

.icon-sort-by-order:before {
  content: "";
}

.icon-sort-by-order-alt:before {
  content: "";
}

.icon-thumbs-up21:before {
  content: "";
}

.icon-thumbs-down21:before {
  content: "";
}

.icon-youtube-sign:before {
  content: "";
}

.icon-youtube2:before {
  content: "";
}

.icon-xing2:before {
  content: "";
}

.icon-xing-sign:before {
  content: "";
}

.icon-youtube-play:before {
  content: "";
}

.icon-dropbox2:before {
  content: "";
}

.icon-stackexchange:before {
  content: "";
}

.icon-instagram2:before {
  content: "";
}

.icon-flickr2:before {
  content: "";
}

.icon-adn1:before {
  content: "";
}

.icon-bitbucket2:before {
  content: "";
}

.icon-bitbucket-sign:before {
  content: "";
}

.icon-tumblr2:before {
  content: "";
}

.icon-tumblr-sign:before {
  content: "";
}

.icon-long-arrow-down:before {
  content: "";
}

.icon-long-arrow-up:before {
  content: "";
}

.icon-long-arrow-left:before {
  content: "";
}

.icon-long-arrow-right:before {
  content: "";
}

.icon-apple1:before {
  content: "";
}

.icon-windows3:before {
  content: "";
}

.icon-android2:before {
  content: "";
}

.icon-linux1:before {
  content: "";
}

.icon-dribbble2:before {
  content: "";
}

.icon-skype2:before {
  content: "";
}

.icon-foursquare2:before {
  content: "";
}

.icon-trello1:before {
  content: "";
}

.icon-female1:before {
  content: "";
}

.icon-male1:before {
  content: "";
}

.icon-gittip:before {
  content: "";
}

.icon-sun21:before {
  content: "";
}

.icon-moon2:before {
  content: "";
}

.icon-archive2:before {
  content: "";
}

.icon-bug1:before {
  content: "";
}

.icon-renren1:before {
  content: "";
}

.icon-weibo2:before {
  content: "";
}

.icon-vk2:before {
  content: "";
}

.icon-duckduckgo:before {
  content: "";
}

.icon-aim:before {
  content: "";
}

.icon-delicious1:before {
  content: "";
}

.icon-paypal1:before {
  content: "";
}

.icon-flattr:before {
  content: "";
}

.icon-android1:before {
  content: "";
}

.icon-eventful:before {
  content: "";
}

.icon-smashmag:before {
  content: "";
}

.icon-gplus:before {
  content: "";
}

.icon-wikipedia:before {
  content: "";
}

.icon-lanyrd:before {
  content: "";
}

.icon-calendar-1:before {
  content: "";
}

.icon-stumbleupon1:before {
  content: "";
}

.icon-fivehundredpx:before {
  content: "";
}

.icon-pinterest1:before {
  content: "";
}

.icon-bitcoin1:before {
  content: "";
}

.icon-w3c:before {
  content: "";
}

.icon-foursquare1:before {
  content: "";
}

.icon-html51:before {
  content: "";
}

.icon-ie:before {
  content: "";
}

.icon-call:before {
  content: "";
}

.icon-grooveshark:before {
  content: "";
}

.icon-ninetyninedesigns:before {
  content: "";
}

.icon-forrst:before {
  content: "";
}

.icon-digg1:before {
  content: "";
}

.icon-spotify1:before {
  content: "";
}

.icon-reddit1:before {
  content: "";
}

.icon-guest:before {
  content: "";
}

.icon-gowalla:before {
  content: "";
}

.icon-appstore:before {
  content: "";
}

.icon-blogger1:before {
  content: "";
}

.icon-cc:before {
  content: "";
}

.icon-dribbble1:before {
  content: "";
}

.icon-evernote:before {
  content: "";
}

.icon-flickr1:before {
  content: "";
}

.icon-google1:before {
  content: "";
}

.icon-viadeo1:before {
  content: "";
}

.icon-instapaper:before {
  content: "";
}

.icon-weibo1:before {
  content: "";
}

.icon-klout:before {
  content: "";
}

.icon-linkedin1:before {
  content: "";
}

.icon-meetup1:before {
  content: "";
}

.icon-vk1:before {
  content: "";
}

.icon-plancast:before {
  content: "";
}

.icon-disqus:before {
  content: "";
}

.icon-rss1:before {
  content: "";
}

.icon-skype1:before {
  content: "";
}

.icon-twitter1:before {
  content: "";
}

.icon-youtube1:before {
  content: "";
}

.icon-vimeo1:before {
  content: "";
}

.icon-windows2:before {
  content: "";
}

.icon-xing1:before {
  content: "";
}

.icon-yahoo1:before {
  content: "";
}

.icon-chrome1:before {
  content: "";
}

.icon-email3:before {
  content: "";
}

.icon-macstore:before {
  content: "";
}

.icon-myspace:before {
  content: "";
}

.icon-podcast1:before {
  content: "";
}

.icon-amazon1:before {
  content: "";
}

.icon-steam1:before {
  content: "";
}

.icon-cloudapp:before {
  content: "";
}

.icon-dropbox1:before {
  content: "";
}

.icon-ebay1:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-github1:before {
  content: "";
}

.icon-github-circled:before {
  content: "";
}

.icon-googleplay:before {
  content: "";
}

.icon-itunes1:before {
  content: "";
}

.icon-plurk:before {
  content: "";
}

.icon-songkick:before {
  content: "";
}

.icon-lastfm1:before {
  content: "";
}

.icon-gmail:before {
  content: "";
}

.icon-pinboard:before {
  content: "";
}

.icon-openid1:before {
  content: "";
}

.icon-quora1:before {
  content: "";
}

.icon-soundcloud1:before {
  content: "";
}

.icon-tumblr1:before {
  content: "";
}

.icon-eventasaurus:before {
  content: "";
}

.icon-wordpress1:before {
  content: "";
}

.icon-yelp1:before {
  content: "";
}

.icon-intensedebate:before {
  content: "";
}

.icon-eventbrite:before {
  content: "";
}

.icon-scribd1:before {
  content: "";
}

.icon-posterous:before {
  content: "";
}

.icon-stripe1:before {
  content: "";
}

.icon-opentable:before {
  content: "";
}

.icon-cart:before {
  content: "";
}

.icon-print1:before {
  content: "";
}

.icon-angellist1:before {
  content: "";
}

.icon-instagram1:before {
  content: "";
}

.icon-dwolla:before {
  content: "";
}

.icon-appnet:before {
  content: "";
}

.icon-statusnet:before {
  content: "";
}

.icon-acrobat:before {
  content: "";
}

.icon-drupal1:before {
  content: "";
}

.icon-buffer:before {
  content: "";
}

.icon-pocket:before {
  content: "";
}

.icon-bitbucket1:before {
  content: "";
}

.icon-lego:before {
  content: "";
}

.icon-login:before {
  content: "";
}

.icon-stackoverflow:before {
  content: "";
}

.icon-hackernews:before {
  content: "";
}

.icon-lkdto:before {
  content: "";
}

.icon-ad:before {
  content: "";
}

.icon-address-book:before {
  content: "";
}

.icon-address-card:before {
  content: "";
}

.icon-adjust:before {
  content: "";
}

.icon-air-freshener:before {
  content: "";
}

.icon-align-center:before {
  content: "";
}

.icon-align-justify:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-allergies:before {
  content: "";
}

.icon-ambulance:before {
  content: "";
}

.icon-american-sign-language-interpreting:before {
  content: "";
}

.icon-anchor:before {
  content: "";
}

.icon-angle-double-down:before {
  content: "";
}

.icon-angle-double-left:before {
  content: "";
}

.icon-angle-double-right:before {
  content: "";
}

.icon-angle-double-up:before {
  content: "";
}

.icon-angle-down1:before {
  content: "";
}

.icon-angle-left1:before {
  content: "";
}

.icon-angle-right1:before {
  content: "";
}

.icon-angle-up1:before {
  content: "";
}

.icon-angry:before {
  content: "";
}

.icon-ankh:before {
  content: "";
}

.icon-apple-alt:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-archway:before {
  content: "";
}

.icon-arrow-alt-circle-down:before {
  content: "";
}

.icon-arrow-alt-circle-left:before {
  content: "";
}

.icon-arrow-alt-circle-right:before {
  content: "";
}

.icon-arrow-alt-circle-up:before {
  content: "";
}

.icon-arrow-circle-down:before {
  content: "";
}

.icon-arrow-circle-left:before {
  content: "";
}

.icon-arrow-circle-right:before {
  content: "";
}

.icon-arrow-circle-up:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-arrows-alt-h:before {
  content: "";
}

.icon-arrows-alt-v:before {
  content: "";
}

.icon-arrows-alt:before {
  content: "";
}

.icon-assistive-listening-systems:before {
  content: "";
}

.icon-asterisk:before {
  content: "";
}

.icon-at:before {
  content: "";
}

.icon-atlas:before {
  content: "";
}

.icon-atom:before {
  content: "";
}

.icon-audio-description:before {
  content: "";
}

.icon-award:before {
  content: "";
}

.icon-backspace:before {
  content: "";
}

.icon-backward:before {
  content: "";
}

.icon-balance-scale:before {
  content: "";
}

.icon-ban:before {
  content: "";
}

.icon-band-aid:before {
  content: "";
}

.icon-barcode:before {
  content: "";
}

.icon-bars:before {
  content: "";
}

.icon-baseball-ball:before {
  content: "";
}

.icon-basketball-ball:before {
  content: "";
}

.icon-bath:before {
  content: "";
}

.icon-battery-empty:before {
  content: "";
}

.icon-battery-full:before {
  content: "";
}

.icon-battery-half:before {
  content: "";
}

.icon-battery-quarter:before {
  content: "";
}

.icon-battery-three-quarters:before {
  content: "";
}

.icon-bed:before {
  content: "";
}

.icon-beer:before {
  content: "";
}

.icon-bell-slash:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-bezier-curve:before {
  content: "";
}

.icon-bible:before {
  content: "";
}

.icon-bicycle:before {
  content: "";
}

.icon-binoculars:before {
  content: "";
}

.icon-birthday-cake:before {
  content: "";
}

.icon-blender:before {
  content: "";
}

.icon-blind:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-bolt:before {
  content: "";
}

.icon-bomb:before {
  content: "";
}

.icon-bone:before {
  content: "";
}

.icon-bong:before {
  content: "";
}

.icon-book-open:before {
  content: "";
}

.icon-book-reader:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-bowling-ball:before {
  content: "";
}

.icon-box-open:before {
  content: "";
}

.icon-box:before {
  content: "";
}

.icon-boxes:before {
  content: "";
}

.icon-braille:before {
  content: "";
}

.icon-brain:before {
  content: "";
}

.icon-briefcase-medical:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-broadcast-tower:before {
  content: "";
}

.icon-broom:before {
  content: "";
}

.icon-brush:before {
  content: "";
}

.icon-bug:before {
  content: "";
}

.icon-building:before {
  content: "";
}

.icon-bullhorn:before {
  content: "";
}

.icon-bullseye:before {
  content: "";
}

.icon-burn:before {
  content: "";
}

.icon-bus-alt:before {
  content: "";
}

.icon-bus:before {
  content: "";
}

.icon-business-time:before {
  content: "";
}

.icon-calculator:before {
  content: "";
}

.icon-calendar-alt:before {
  content: "";
}

.icon-calendar-check:before {
  content: "";
}

.icon-calendar-minus:before {
  content: "";
}

.icon-calendar-plus:before {
  content: "";
}

.icon-calendar-times:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-camera-retro:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-cannabis:before {
  content: "";
}

.icon-capsules:before {
  content: "";
}

.icon-car-alt:before {
  content: "";
}

.icon-car-battery:before {
  content: "";
}

.icon-car-crash:before {
  content: "";
}

.icon-car-side:before {
  content: "";
}

.icon-car:before {
  content: "";
}

.icon-caret-down:before {
  content: "";
}

.icon-caret-left:before {
  content: "";
}

.icon-caret-right:before {
  content: "";
}

.icon-caret-square-down:before {
  content: "";
}

.icon-caret-square-left:before {
  content: "";
}

.icon-caret-square-right:before {
  content: "";
}

.icon-caret-square-up:before {
  content: "";
}

.icon-caret-up:before {
  content: "";
}

.icon-cart-arrow-down:before {
  content: "";
}

.icon-cart-plus:before {
  content: "";
}

.icon-certificate:before {
  content: "";
}

.icon-chalkboard-teacher:before {
  content: "";
}

.icon-chalkboard:before {
  content: "";
}

.icon-charging-station:before {
  content: "";
}

.icon-chart-area:before {
  content: "";
}

.icon-chart-bar:before {
  content: "";
}

.icon-chart-line:before {
  content: "";
}

.icon-chart-pie:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-check-double:before {
  content: "";
}

.icon-check-square:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-chess-bishop:before {
  content: "";
}

.icon-chess-board:before {
  content: "";
}

.icon-chess-king:before {
  content: "";
}

.icon-chess-knight:before {
  content: "";
}

.icon-chess-pawn:before {
  content: "";
}

.icon-chess-queen:before {
  content: "";
}

.icon-chess-rook:before {
  content: "";
}

.icon-chess:before {
  content: "";
}

.icon-chevron-circle-down:before {
  content: "";
}

.icon-chevron-circle-left:before {
  content: "";
}

.icon-chevron-circle-right:before {
  content: "";
}

.icon-chevron-circle-up:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-child:before {
  content: "";
}

.icon-church:before {
  content: "";
}

.icon-circle-notch:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-city:before {
  content: "";
}

.icon-clipboard-check:before {
  content: "";
}

.icon-clipboard-list:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-clone:before {
  content: "";
}

.icon-closed-captioning:before {
  content: "";
}

.icon-cloud-download-alt:before {
  content: "";
}

.icon-cloud-upload-alt:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-cocktail:before {
  content: "";
}

.icon-code-branch:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-cogs:before {
  content: "";
}

.icon-coins:before {
  content: "";
}

.icon-columns:before {
  content: "";
}

.icon-comment-alt:before {
  content: "";
}

.icon-comment-dollar:before {
  content: "";
}

.icon-comment-dots:before {
  content: "";
}

.icon-comment-slash:before {
  content: "";
}

.icon-comment:before {
  content: "";
}

.icon-comments-dollar:before {
  content: "";
}

.icon-comments:before {
  content: "";
}

.icon-compact-disc:before {
  content: "";
}

.icon-compass:before {
  content: "";
}

.icon-compress:before {
  content: "";
}

.icon-concierge-bell:before {
  content: "";
}

.icon-cookie-bite:before {
  content: "";
}

.icon-cookie:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-copyright:before {
  content: "";
}

.icon-couch:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-crop-alt:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-cross:before {
  content: "";
}

.icon-crosshairs:before {
  content: "";
}

.icon-crow:before {
  content: "";
}

.icon-crown:before {
  content: "";
}

.icon-cube:before {
  content: "";
}

.icon-cubes:before {
  content: "";
}

.icon-cut:before {
  content: "";
}

.icon-database:before {
  content: "";
}

.icon-deaf:before {
  content: "";
}

.icon-desktop:before {
  content: "";
}

.icon-dharmachakra:before {
  content: "";
}

.icon-diagnoses:before {
  content: "";
}

.icon-dice-five:before {
  content: "";
}

.icon-dice-four:before {
  content: "";
}

.icon-dice-one:before {
  content: "";
}

.icon-dice-six:before {
  content: "";
}

.icon-dice-three:before {
  content: "";
}

.icon-dice-two:before {
  content: "";
}

.icon-dice:before {
  content: "";
}

.icon-digital-tachograph:before {
  content: "";
}

.icon-directions:before {
  content: "";
}

.icon-divide:before {
  content: "";
}

.icon-dizzy:before {
  content: "";
}

.icon-dna:before {
  content: "";
}

.icon-dollar-sign:before {
  content: "";
}

.icon-dolly-flatbed:before {
  content: "";
}

.icon-dolly:before {
  content: "";
}

.icon-donate:before {
  content: "";
}

.icon-door-closed:before {
  content: "";
}

.icon-door-open:before {
  content: "";
}

.icon-dot-circle:before {
  content: "";
}

.icon-dove:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-drafting-compass:before {
  content: "";
}

.icon-draw-polygon:before {
  content: "";
}

.icon-drum-steelpan:before {
  content: "";
}

.icon-drum:before {
  content: "";
}

.icon-dumbbell:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-eject:before {
  content: "";
}

.icon-ellipsis-h:before {
  content: "";
}

.icon-ellipsis-v:before {
  content: "";
}

.icon-envelope-open-text:before {
  content: "";
}

.icon-envelope-open:before {
  content: "";
}

.icon-envelope-square:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-equals:before {
  content: "";
}

.icon-eraser:before {
  content: "";
}

.icon-euro-sign:before {
  content: "";
}

.icon-exchange-alt:before {
  content: "";
}

.icon-exclamation-circle:before {
  content: "";
}

.icon-exclamation-triangle:before {
  content: "";
}

.icon-exclamation:before {
  content: "";
}

.icon-expand-arrows-alt:before {
  content: "";
}

.icon-expand:before {
  content: "";
}

.icon-external-link-alt:before {
  content: "";
}

.icon-external-link-square-alt:before {
  content: "";
}

.icon-eye-dropper:before {
  content: "";
}

.icon-eye-slash:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-fast-backward:before {
  content: "";
}

.icon-fast-forward:before {
  content: "";
}

.icon-fax:before {
  content: "";
}

.icon-feather-alt:before {
  content: "";
}

.icon-feather:before {
  content: "";
}

.icon-female:before {
  content: "";
}

.icon-fighter-jet:before {
  content: "";
}

.icon-file-alt:before {
  content: "";
}

.icon-file-archive:before {
  content: "";
}

.icon-file-audio:before {
  content: "";
}

.icon-file-code:before {
  content: "";
}

.icon-file-contract:before {
  content: "";
}

.icon-file-download:before {
  content: "";
}

.icon-file-excel:before {
  content: "";
}

.icon-file-export:before {
  content: "";
}

.icon-file-image:before {
  content: "";
}

.icon-file-import:before {
  content: "";
}

.icon-file-invoice-dollar:before {
  content: "";
}

.icon-file-invoice:before {
  content: "";
}

.icon-file-medical-alt:before {
  content: "";
}

.icon-file-medical:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-file-powerpoint:before {
  content: "";
}

.icon-file-prescription:before {
  content: "";
}

.icon-file-signature:before {
  content: "";
}

.icon-file-upload:before {
  content: "";
}

.icon-file-video:before {
  content: "";
}

.icon-file-word:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-fill-drip:before {
  content: "";
}

.icon-fill:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-fingerprint:before {
  content: "";
}

.icon-fire-extinguisher:before {
  content: "";
}

.icon-fire:before {
  content: "";
}

.icon-first-aid:before {
  content: "";
}

.icon-fish:before {
  content: "";
}

.icon-flag-checkered:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-flask:before {
  content: "";
}

.icon-flushed:before {
  content: "";
}

.icon-folder-minus:before {
  content: "";
}

.icon-folder-open:before {
  content: "";
}

.icon-folder-plus:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-font-awesome-logo-full:before {
  content: "";
}

.icon-font:before {
  content: "";
}

.icon-football-ball:before {
  content: "";
}

.icon-forward:before {
  content: "";
}

.icon-frog:before {
  content: "";
}

.icon-frown-open:before {
  content: "";
}

.icon-frown:before {
  content: "";
}

.icon-funnel-dollar:before {
  content: "";
}

.icon-futbol:before {
  content: "";
}

.icon-gamepad:before {
  content: "";
}

.icon-gas-pump:before {
  content: "";
}

.icon-gavel:before {
  content: "";
}

.icon-gem:before {
  content: "";
}

.icon-genderless:before {
  content: "";
}

.icon-gift:before {
  content: "";
}

.icon-glass-martini-alt:before {
  content: "";
}

.icon-glass-martini:before {
  content: "";
}

.icon-glasses:before {
  content: "";
}

.icon-globe-africa:before {
  content: "";
}

.icon-globe-americas:before {
  content: "";
}

.icon-globe-asia:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-golf-ball:before {
  content: "";
}

.icon-gopuram:before {
  content: "";
}

.icon-graduation-cap:before {
  content: "";
}

.icon-greater-than-equal:before {
  content: "";
}

.icon-greater-than:before {
  content: "";
}

.icon-grimace:before {
  content: "";
}

.icon-grin-alt:before {
  content: "";
}

.icon-grin-beam-sweat:before {
  content: "";
}

.icon-grin-beam:before {
  content: "";
}

.icon-grin-hearts:before {
  content: "";
}

.icon-grin-squint-tears:before {
  content: "";
}

.icon-grin-squint:before {
  content: "";
}

.icon-grin-stars:before {
  content: "";
}

.icon-grin-tears:before {
  content: "";
}

.icon-grin-tongue-squint:before {
  content: "";
}

.icon-grin-tongue-wink:before {
  content: "";
}

.icon-grin-tongue:before {
  content: "";
}

.icon-grin-wink:before {
  content: "";
}

.icon-grin:before {
  content: "";
}

.icon-grip-horizontal:before {
  content: "";
}

.icon-grip-vertical:before {
  content: "";
}

.icon-h-square:before {
  content: "";
}

.icon-hamsa:before {
  content: "";
}

.icon-hand-holding-heart:before {
  content: "";
}

.icon-hand-holding-usd:before {
  content: "";
}

.icon-hand-holding:before {
  content: "";
}

.icon-hand-lizard:before {
  content: "";
}

.icon-hand-paper:before {
  content: "";
}

.icon-hand-peace:before {
  content: "";
}

.icon-hand-point-down:before {
  content: "";
}

.icon-hand-point-left:before {
  content: "";
}

.icon-hand-point-right:before {
  content: "";
}

.icon-hand-point-up:before {
  content: "";
}

.icon-hand-pointer:before {
  content: "";
}

.icon-hand-rock:before {
  content: "";
}

.icon-hand-scissors:before {
  content: "";
}

.icon-hand-spock:before {
  content: "";
}

.icon-hands-helping:before {
  content: "";
}

.icon-hands:before {
  content: "";
}

.icon-handshake:before {
  content: "";
}

.icon-hashtag:before {
  content: "";
}

.icon-haykal:before {
  content: "";
}

.icon-hdd:before {
  content: "";
}

.icon-heading:before {
  content: "";
}

.icon-headphones-alt:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-headset:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-heartbeat:before {
  content: "";
}

.icon-helicopter:before {
  content: "";
}

.icon-highlighter:before {
  content: "";
}

.icon-history:before {
  content: "";
}

.icon-hockey-puck:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-hospital-alt:before {
  content: "";
}

.icon-hospital-symbol:before {
  content: "";
}

.icon-hospital:before {
  content: "";
}

.icon-hot-tub:before {
  content: "";
}

.icon-hotel:before {
  content: "";
}

.icon-hourglass-end:before {
  content: "";
}

.icon-hourglass-half:before {
  content: "";
}

.icon-hourglass-start:before {
  content: "";
}

.icon-hourglass:before {
  content: "";
}

.icon-i-cursor:before {
  content: "";
}

.icon-id-badge:before {
  content: "";
}

.icon-id-card-alt:before {
  content: "";
}

.icon-id-card:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-images:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-indent:before {
  content: "";
}

.icon-industry:before {
  content: "";
}

.icon-infinity:before {
  content: "";
}

.icon-info-circle:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-jedi:before {
  content: "";
}

.icon-joint:before {
  content: "";
}

.icon-journal-whills:before {
  content: "";
}

.icon-kaaba:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-keyboard:before {
  content: "";
}

.icon-khanda:before {
  content: "";
}

.icon-kiss-beam:before {
  content: "";
}

.icon-kiss-wink-heart:before {
  content: "";
}

.icon-kiss:before {
  content: "";
}

.icon-kiwi-bird:before {
  content: "";
}

.icon-landmark:before {
  content: "";
}

.icon-language:before {
  content: "";
}

.icon-laptop-code:before {
  content: "";
}

.icon-laptop:before {
  content: "";
}

.icon-laugh-beam:before {
  content: "";
}

.icon-laugh-squint:before {
  content: "";
}

.icon-laugh-wink:before {
  content: "";
}

.icon-laugh:before {
  content: "";
}

.icon-layer-group:before {
  content: "";
}

.icon-leaf:before {
  content: "";
}

.icon-lemon:before {
  content: "";
}

.icon-less-than-equal:before {
  content: "";
}

.icon-less-than:before {
  content: "";
}

.icon-level-down-alt:before {
  content: "";
}

.icon-level-up-alt:before {
  content: "";
}

.icon-life-ring:before {
  content: "";
}

.icon-lightbulb:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-lira-sign:before {
  content: "";
}

.icon-list-alt:before {
  content: "";
}

.icon-list-ol:before {
  content: "";
}

.icon-list-ul:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-location-arrow:before {
  content: "";
}

.icon-lock-open:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-long-arrow-alt-down:before {
  content: "";
}

.icon-long-arrow-alt-left:before {
  content: "";
}

.icon-long-arrow-alt-right:before {
  content: "";
}

.icon-long-arrow-alt-up:before {
  content: "";
}

.icon-low-vision:before {
  content: "";
}

.icon-luggage-cart:before {
  content: "";
}

.icon-magic:before {
  content: "";
}

.icon-magnet:before {
  content: "";
}

.icon-mail-bulk:before {
  content: "";
}

.icon-male:before {
  content: "";
}

.icon-map-marked-alt:before {
  content: "";
}

.icon-map-marked:before {
  content: "";
}

.icon-map-marker-alt:before {
  content: "";
}

.icon-map-marker:before {
  content: "";
}

.icon-map-pin:before {
  content: "";
}

.icon-map-signs:before {
  content: "";
}

.icon-map:before {
  content: "";
}

.icon-marker:before {
  content: "";
}

.icon-mars-double:before {
  content: "";
}

.icon-mars-stroke-h:before {
  content: "";
}

.icon-mars-stroke-v:before {
  content: "";
}

.icon-mars-stroke:before {
  content: "";
}

.icon-mars:before {
  content: "";
}

.icon-medal:before {
  content: "";
}

.icon-medkit:before {
  content: "";
}

.icon-meh-blank:before {
  content: "";
}

.icon-meh-rolling-eyes:before {
  content: "";
}

.icon-meh:before {
  content: "";
}

.icon-memory:before {
  content: "";
}

.icon-menorah:before {
  content: "";
}

.icon-mercury:before {
  content: "";
}

.icon-microchip:before {
  content: "";
}

.icon-microphone-alt-slash:before {
  content: "";
}

.icon-microphone-alt:before {
  content: "";
}

.icon-microphone-slash:before {
  content: "";
}

.icon-microphone:before {
  content: "";
}

.icon-microscope:before {
  content: "";
}

.icon-minus-circle:before {
  content: "";
}

.icon-minus-square:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-mobile-alt:before {
  content: "";
}

.icon-mobile:before {
  content: "";
}

.icon-money-bill-alt:before {
  content: "";
}

.icon-money-bill-wave-alt:before {
  content: "";
}

.icon-money-bill-wave:before {
  content: "";
}

.icon-money-bill:before {
  content: "";
}

.icon-money-check-alt:before {
  content: "";
}

.icon-money-check:before {
  content: "";
}

.icon-monument:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-mortar-pestle:before {
  content: "";
}

.icon-mosque:before {
  content: "";
}

.icon-motorcycle:before {
  content: "";
}

.icon-mouse-pointer:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-neuter:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-not-equal:before {
  content: "";
}

.icon-notes-medical:before {
  content: "";
}

.icon-object-group:before {
  content: "";
}

.icon-object-ungroup:before {
  content: "";
}

.icon-oil-can:before {
  content: "";
}

.icon-om:before {
  content: "";
}

.icon-outdent:before {
  content: "";
}

.icon-paint-brush:before {
  content: "";
}

.icon-paint-roller:before {
  content: "";
}

.icon-palette:before {
  content: "";
}

.icon-pallet:before {
  content: "";
}

.icon-paper-plane:before {
  content: "";
}

.icon-paperclip:before {
  content: "";
}

.icon-parachute-box:before {
  content: "";
}

.icon-paragraph:before {
  content: "";
}

.icon-parking:before {
  content: "";
}

.icon-passport:before {
  content: "";
}

.icon-pastafarianism:before {
  content: "";
}

.icon-paste:before {
  content: "";
}

.icon-pause-circle:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-paw:before {
  content: "";
}

.icon-peace:before {
  content: "";
}

.icon-pen-alt:before {
  content: "";
}

.icon-pen-fancy:before {
  content: "";
}

.icon-pen-nib:before {
  content: "";
}

.icon-pen-square:before {
  content: "";
}

.icon-pen:before {
  content: "";
}

.icon-pencil-alt:before {
  content: "";
}

.icon-pencil-ruler:before {
  content: "";
}

.icon-people-carry:before {
  content: "";
}

.icon-percent:before {
  content: "";
}

.icon-percentage:before {
  content: "";
}

.icon-phone-slash:before {
  content: "";
}

.icon-phone-square:before {
  content: "";
}

.icon-phone-volume:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-piggy-bank:before {
  content: "";
}

.icon-pills:before {
  content: "";
}

.icon-place-of-worship:before {
  content: "";
}

.icon-plane-arrival:before {
  content: "";
}

.icon-plane-departure:before {
  content: "";
}

.icon-plane:before {
  content: "";
}

.icon-play-circle:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-plug:before {
  content: "";
}

.icon-plus-circle:before {
  content: "";
}

.icon-plus-square:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-podcast:before {
  content: "";
}

.icon-poll-h:before {
  content: "";
}

.icon-poll:before {
  content: "";
}

.icon-poo:before {
  content: "";
}

.icon-poop:before {
  content: "";
}

.icon-portrait:before {
  content: "";
}

.icon-pound-sign:before {
  content: "";
}

.icon-power-off:before {
  content: "";
}

.icon-pray:before {
  content: "";
}

.icon-praying-hands:before {
  content: "";
}

.icon-prescription-bottle-alt:before {
  content: "";
}

.icon-prescription-bottle:before {
  content: "";
}

.icon-prescription:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-procedures:before {
  content: "";
}

.icon-project-diagram:before {
  content: "";
}

.icon-puzzle-piece:before {
  content: "";
}

.icon-qrcode:before {
  content: "";
}

.icon-question-circle:before {
  content: "";
}

.icon-question:before {
  content: "";
}

.icon-quidditch:before {
  content: "";
}

.icon-quote-left:before {
  content: "";
}

.icon-quote-right:before {
  content: "";
}

.icon-quran:before {
  content: "";
}

.icon-random:before {
  content: "";
}

.icon-receipt:before {
  content: "";
}

.icon-recycle:before {
  content: "";
}

.icon-redo-alt:before {
  content: "";
}

.icon-redo:before {
  content: "";
}

.icon-registered:before {
  content: "";
}

.icon-reply-all:before {
  content: "";
}

.icon-reply:before {
  content: "";
}

.icon-retweet:before {
  content: "";
}

.icon-ribbon:before {
  content: "";
}

.icon-road:before {
  content: "";
}

.icon-robot:before {
  content: "";
}

.icon-rocket:before {
  content: "";
}

.icon-route:before {
  content: "";
}

.icon-rss-square:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-ruble-sign:before {
  content: "";
}

.icon-ruler-combined:before {
  content: "";
}

.icon-ruler-horizontal:before {
  content: "";
}

.icon-ruler-vertical:before {
  content: "";
}

.icon-ruler:before {
  content: "";
}

.icon-rupee-sign:before {
  content: "";
}

.icon-sad-cry:before {
  content: "";
}

.icon-sad-tear:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-school:before {
  content: "";
}

.icon-screwdriver:before {
  content: "";
}

.icon-search-dollar:before {
  content: "";
}

.icon-search-location:before {
  content: "";
}

.icon-search-minus:before {
  content: "";
}

.icon-search-plus:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-seedling:before {
  content: "";
}

.icon-server:before {
  content: "";
}

.icon-shapes:before {
  content: "";
}

.icon-share-alt-square:before {
  content: "";
}

.icon-share-alt:before {
  content: "";
}

.icon-share-square:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-shekel-sign:before {
  content: "";
}

.icon-shield-alt:before {
  content: "";
}

.icon-ship:before {
  content: "";
}

.icon-shipping-fast:before {
  content: "";
}

.icon-shoe-prints:before {
  content: "";
}

.icon-shopping-bag:before {
  content: "";
}

.icon-shopping-basket:before {
  content: "";
}

.icon-shopping-cart1:before {
  content: "";
}

.icon-shower:before {
  content: "";
}

.icon-shuttle-van:before {
  content: "";
}

.icon-sign-in-alt:before {
  content: "";
}

.icon-sign-language:before {
  content: "";
}

.icon-sign-out-alt:before {
  content: "";
}

.icon-sign:before {
  content: "";
}

.icon-signal:before {
  content: "";
}

.icon-signature:before {
  content: "";
}

.icon-sitemap:before {
  content: "";
}

.icon-skull:before {
  content: "";
}

.icon-sliders-h:before {
  content: "";
}

.icon-smile-beam:before {
  content: "";
}

.icon-smile-wink:before {
  content: "";
}

.icon-smile:before {
  content: "";
}

.icon-smoking-ban:before {
  content: "";
}

.icon-smoking:before {
  content: "";
}

.icon-snowflake:before {
  content: "";
}

.icon-socks:before {
  content: "";
}

.icon-solar-panel:before {
  content: "";
}

.icon-sort-alpha-down:before {
  content: "";
}

.icon-sort-alpha-up:before {
  content: "";
}

.icon-sort-amount-down:before {
  content: "";
}

.icon-sort-amount-up:before {
  content: "";
}

.icon-sort-down:before {
  content: "";
}

.icon-sort-numeric-down:before {
  content: "";
}

.icon-sort-numeric-up:before {
  content: "";
}

.icon-sort-up:before {
  content: "";
}

.icon-sort:before {
  content: "";
}

.icon-spa:before {
  content: "";
}

.icon-space-shuttle:before {
  content: "";
}

.icon-spinner:before {
  content: "";
}

.icon-splotch:before {
  content: "";
}

.icon-spray-can:before {
  content: "";
}

.icon-square-full:before {
  content: "";
}

.icon-square-root-alt:before {
  content: "";
}

.icon-square:before {
  content: "";
}

.icon-stamp:before {
  content: "";
}

.icon-star-and-crescent:before {
  content: "";
}

.icon-star-half-alt:before {
  content: "";
}

.icon-star-half:before {
  content: "";
}

.icon-star-of-david:before {
  content: "";
}

.icon-star-of-life:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-step-backward:before {
  content: "";
}

.icon-step-forward:before {
  content: "";
}

.icon-stethoscope:before {
  content: "";
}

.icon-sticky-note:before {
  content: "";
}

.icon-stop-circle:before {
  content: "";
}

.icon-stop:before {
  content: "";
}

.icon-stopwatch:before {
  content: "";
}

.icon-store-alt:before {
  content: "";
}

.icon-store:before {
  content: "";
}

.icon-stream:before {
  content: "";
}

.icon-street-view:before {
  content: "";
}

.icon-strikethrough:before {
  content: "";
}

.icon-stroopwafel:before {
  content: "";
}

.icon-subscript:before {
  content: "";
}

.icon-subway:before {
  content: "";
}

.icon-suitcase-rolling:before {
  content: "";
}

.icon-suitcase:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-superscript:before {
  content: "";
}

.icon-surprise:before {
  content: "";
}

.icon-swatchbook:before {
  content: "";
}

.icon-swimmer:before {
  content: "";
}

.icon-swimming-pool:before {
  content: "";
}

.icon-synagogue:before {
  content: "";
}

.icon-sync-alt:before {
  content: "";
}

.icon-sync:before {
  content: "";
}

.icon-syringe:before {
  content: "";
}

.icon-table-tennis:before {
  content: "";
}

.icon-table:before {
  content: "";
}

.icon-tablet-alt:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-tablets:before {
  content: "";
}

.icon-tachometer-alt:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-tags:before {
  content: "";
}

.icon-tape:before {
  content: "";
}

.icon-tasks:before {
  content: "";
}

.icon-taxi:before {
  content: "";
}

.icon-teeth-open:before {
  content: "";
}

.icon-teeth:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-text-height:before {
  content: "";
}

.icon-text-width:before {
  content: "";
}

.icon-th-large:before {
  content: "";
}

.icon-th-list:before {
  content: "";
}

.icon-th:before {
  content: "";
}

.icon-theater-masks:before {
  content: "";
}

.icon-thermometer-empty:before {
  content: "";
}

.icon-thermometer-full:before {
  content: "";
}

.icon-thermometer-half:before {
  content: "";
}

.icon-thermometer-quarter:before {
  content: "";
}

.icon-thermometer-three-quarters:before {
  content: "";
}

.icon-thermometer:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-thumbtack:before {
  content: "";
}

.icon-ticket-alt:before {
  content: "";
}

.icon-times-circle:before {
  content: "";
}

.icon-times:before {
  content: "";
}

.icon-tint-slash:before {
  content: "";
}

.icon-tint:before {
  content: "";
}

.icon-tired:before {
  content: "";
}

.icon-toggle-off:before {
  content: "";
}

.icon-toggle-on:before {
  content: "";
}

.icon-toolbox:before {
  content: "";
}

.icon-tooth:before {
  content: "";
}

.icon-torah:before {
  content: "";
}

.icon-torii-gate:before {
  content: "";
}

.icon-trademark:before {
  content: "";
}

.icon-traffic-light:before {
  content: "";
}

.icon-train:before {
  content: "";
}

.icon-transgender-alt:before {
  content: "";
}

.icon-transgender:before {
  content: "";
}

.icon-trash-alt:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-tree:before {
  content: "";
}

.icon-trophy:before {
  content: "";
}

.icon-truck-loading:before {
  content: "";
}

.icon-truck-monster:before {
  content: "";
}

.icon-truck-moving:before {
  content: "";
}

.icon-truck-pickup:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-tshirt:before {
  content: "";
}

.icon-tty:before {
  content: "";
}

.icon-tv:before {
  content: "";
}

.icon-umbrella-beach:before {
  content: "";
}

.icon-umbrella:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-undo-alt:before {
  content: "";
}

.icon-undo:before {
  content: "";
}

.icon-universal-access:before {
  content: "";
}

.icon-university:before {
  content: "";
}

.icon-unlink:before {
  content: "";
}

.icon-unlock-alt:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-user-alt-slash:before {
  content: "";
}

.icon-user-alt:before {
  content: "";
}

.icon-user-astronaut:before {
  content: "";
}

.icon-user-check:before {
  content: "";
}

.icon-user-circle:before {
  content: "";
}

.icon-user-clock:before {
  content: "";
}

.icon-user-cog:before {
  content: "";
}

.icon-user-edit:before {
  content: "";
}

.icon-user-friends:before {
  content: "";
}

.icon-user-graduate:before {
  content: "";
}

.icon-user-lock:before {
  content: "";
}

.icon-user-md:before {
  content: "";
}

.icon-user-minus:before {
  content: "";
}

.icon-user-ninja:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-secret:before {
  content: "";
}

.icon-user-shield:before {
  content: "";
}

.icon-user-slash:before {
  content: "";
}

.icon-user-tag:before {
  content: "";
}

.icon-user-tie:before {
  content: "";
}

.icon-user-times:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-users-cog:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-utensil-spoon:before {
  content: "";
}

.icon-utensils:before {
  content: "";
}

.icon-vector-square:before {
  content: "";
}

.icon-venus-double:before {
  content: "";
}

.icon-venus-mars:before {
  content: "";
}

.icon-venus:before {
  content: "";
}

.icon-vial:before {
  content: "";
}

.icon-vials:before {
  content: "";
}

.icon-video-slash:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-vihara:before {
  content: "";
}

.icon-volleyball-ball:before {
  content: "";
}

.icon-volume-down:before {
  content: "";
}

.icon-volume-off:before {
  content: "";
}

.icon-volume-up:before {
  content: "";
}

.icon-walking:before {
  content: "";
}

.icon-wallet:before {
  content: "";
}

.icon-warehouse:before {
  content: "";
}

.icon-weight-hanging:before {
  content: "";
}

.icon-weight:before {
  content: "";
}

.icon-wheelchair:before {
  content: "";
}

.icon-wifi:before {
  content: "";
}

.icon-window-close:before {
  content: "";
}

.icon-window-maximize:before {
  content: "";
}

.icon-window-minimize:before {
  content: "";
}

.icon-window-restore:before {
  content: "";
}

.icon-wine-glass-alt:before {
  content: "";
}

.icon-wine-glass:before {
  content: "";
}

.icon-won-sign:before {
  content: "";
}

.icon-wrench:before {
  content: "";
}

.icon-x-ray:before {
  content: "";
}

.icon-yen-sign:before {
  content: "";
}

.icon-yin-yang:before {
  content: "";
}

.icon-address-book1:before {
  content: "";
}

.icon-address-card1:before {
  content: "";
}

.icon-angry1:before {
  content: "";
}

.icon-arrow-alt-circle-down1:before {
  content: "";
}

.icon-arrow-alt-circle-left1:before {
  content: "";
}

.icon-arrow-alt-circle-right1:before {
  content: "";
}

.icon-arrow-alt-circle-up1:before {
  content: "";
}

.icon-bell-slash1:before {
  content: "";
}

.icon-bell1:before {
  content: "";
}

.icon-bookmark1:before {
  content: "";
}

.icon-building1:before {
  content: "";
}

.icon-calendar-alt1:before {
  content: "";
}

.icon-calendar-check1:before {
  content: "";
}

.icon-calendar-minus1:before {
  content: "";
}

.icon-calendar-plus1:before {
  content: "";
}

.icon-calendar-times1:before {
  content: "";
}

.icon-calendar1:before {
  content: "";
}

.icon-caret-square-down1:before {
  content: "";
}

.icon-caret-square-left1:before {
  content: "";
}

.icon-caret-square-right1:before {
  content: "";
}

.icon-caret-square-up1:before {
  content: "";
}

.icon-chart-bar1:before {
  content: "";
}

.icon-check-circle1:before {
  content: "";
}

.icon-check-square1:before {
  content: "";
}

.icon-circle1:before {
  content: "";
}

.icon-clipboard1:before {
  content: "";
}

.icon-clock1:before {
  content: "";
}

.icon-clone1:before {
  content: "";
}

.icon-closed-captioning1:before {
  content: "";
}

.icon-comment-alt1:before {
  content: "";
}

.icon-comment-dots1:before {
  content: "";
}

.icon-comment1:before {
  content: "";
}

.icon-comments1:before {
  content: "";
}

.icon-compass1:before {
  content: "";
}

.icon-copy1:before {
  content: "";
}

.icon-copyright1:before {
  content: "";
}

.icon-credit-card1:before {
  content: "";
}

.icon-dizzy1:before {
  content: "";
}

.icon-dot-circle1:before {
  content: "";
}

.icon-edit1:before {
  content: "";
}

.icon-envelope-open1:before {
  content: "";
}

.icon-envelope1:before {
  content: "";
}

.icon-eye-slash1:before {
  content: "";
}

.icon-eye1:before {
  content: "";
}

.icon-file-alt1:before {
  content: "";
}

.icon-file-archive1:before {
  content: "";
}

.icon-file-audio1:before {
  content: "";
}

.icon-file-code1:before {
  content: "";
}

.icon-file-excel1:before {
  content: "";
}

.icon-file-image1:before {
  content: "";
}

.icon-file-pdf1:before {
  content: "";
}

.icon-file-powerpoint1:before {
  content: "";
}

.icon-file-video1:before {
  content: "";
}

.icon-file-word1:before {
  content: "";
}

.icon-file1:before {
  content: "";
}

.icon-flag1:before {
  content: "";
}

.icon-flushed1:before {
  content: "";
}

.icon-folder-open1:before {
  content: "";
}

.icon-folder1:before {
  content: "";
}

.icon-font-awesome-logo-full1:before {
  content: "";
}

.icon-frown-open1:before {
  content: "";
}

.icon-frown1:before {
  content: "";
}

.icon-futbol1:before {
  content: "";
}

.icon-gem1:before {
  content: "";
}

.icon-grimace1:before {
  content: "";
}

.icon-grin-alt1:before {
  content: "";
}

.icon-grin-beam-sweat1:before {
  content: "";
}

.icon-grin-beam1:before {
  content: "";
}

.icon-grin-hearts1:before {
  content: "";
}

.icon-grin-squint-tears1:before {
  content: "";
}

.icon-grin-squint1:before {
  content: "";
}

.icon-grin-stars1:before {
  content: "";
}

.icon-grin-tears1:before {
  content: "";
}

.icon-grin-tongue-squint1:before {
  content: "";
}

.icon-grin-tongue-wink1:before {
  content: "";
}

.icon-grin-tongue1:before {
  content: "";
}

.icon-grin-wink1:before {
  content: "";
}

.icon-grin1:before {
  content: "";
}

.icon-hand-lizard1:before {
  content: "";
}

.icon-hand-paper1:before {
  content: "";
}

.icon-hand-peace1:before {
  content: "";
}

.icon-hand-point-down1:before {
  content: "";
}

.icon-hand-point-left1:before {
  content: "";
}

.icon-hand-point-right1:before {
  content: "";
}

.icon-hand-point-up1:before {
  content: "";
}

.icon-hand-pointer1:before {
  content: "";
}

.icon-hand-rock1:before {
  content: "";
}

.icon-hand-scissors1:before {
  content: "";
}

.icon-hand-spock1:before {
  content: "";
}

.icon-handshake1:before {
  content: "";
}

.icon-hdd1:before {
  content: "";
}

.icon-heart1:before {
  content: "";
}

.icon-hospital1:before {
  content: "";
}

.icon-hourglass1:before {
  content: "";
}

.icon-id-badge1:before {
  content: "";
}

.icon-id-card1:before {
  content: "";
}

.icon-image1:before {
  content: "";
}

.icon-images1:before {
  content: "";
}

.icon-keyboard1:before {
  content: "";
}

.icon-kiss-beam1:before {
  content: "";
}

.icon-kiss-wink-heart1:before {
  content: "";
}

.icon-kiss1:before {
  content: "";
}

.icon-laugh-beam1:before {
  content: "";
}

.icon-laugh-squint1:before {
  content: "";
}

.icon-laugh-wink1:before {
  content: "";
}

.icon-laugh1:before {
  content: "";
}

.icon-lemon1:before {
  content: "";
}

.icon-life-ring1:before {
  content: "";
}

.icon-lightbulb1:before {
  content: "";
}

.icon-list-alt1:before {
  content: "";
}

.icon-map1:before {
  content: "";
}

.icon-meh-blank1:before {
  content: "";
}

.icon-meh-rolling-eyes1:before {
  content: "";
}

.icon-meh1:before {
  content: "";
}

.icon-minus-square1:before {
  content: "";
}

.icon-money-bill-alt1:before {
  content: "";
}

.icon-moon1:before {
  content: "";
}

.icon-newspaper1:before {
  content: "";
}

.icon-object-group1:before {
  content: "";
}

.icon-object-ungroup1:before {
  content: "";
}

.icon-paper-plane1:before {
  content: "";
}

.icon-pause-circle1:before {
  content: "";
}

.icon-play-circle1:before {
  content: "";
}

.icon-plus-square1:before {
  content: "";
}

.icon-question-circle1:before {
  content: "";
}

.icon-registered1:before {
  content: "";
}

.icon-sad-cry1:before {
  content: "";
}

.icon-sad-tear1:before {
  content: "";
}

.icon-save1:before {
  content: "";
}

.icon-share-square1:before {
  content: "";
}

.icon-smile-beam1:before {
  content: "";
}

.icon-smile-wink1:before {
  content: "";
}

.icon-smile1:before {
  content: "";
}

.icon-snowflake1:before {
  content: "";
}

.icon-square1:before {
  content: "";
}

.icon-star-half1:before {
  content: "";
}

.icon-star1:before {
  content: "";
}

.icon-sticky-note1:before {
  content: "";
}

.icon-stop-circle1:before {
  content: "";
}

.icon-sun1:before {
  content: "";
}

.icon-surprise1:before {
  content: "";
}

.icon-thumbs-down1:before {
  content: "";
}

.icon-thumbs-up1:before {
  content: "";
}

.icon-times-circle1:before {
  content: "";
}

.icon-tired1:before {
  content: "";
}

.icon-trash-alt1:before {
  content: "";
}

.icon-user-circle1:before {
  content: "";
}

.icon-user1:before {
  content: "";
}

.icon-window-close1:before {
  content: "";
}

.icon-window-maximize1:before {
  content: "";
}

.icon-window-minimize1:before {
  content: "";
}

.icon-window-restore1:before {
  content: "";
}

.icon-px:before {
  content: "";
}

.icon-accessible-icon:before {
  content: "";
}

.icon-accusoft:before {
  content: "";
}

.icon-adn:before {
  content: "";
}

.icon-adversal:before {
  content: "";
}

.icon-affiliatetheme:before {
  content: "";
}

.icon-algolia:before {
  content: "";
}

.icon-alipay:before {
  content: "";
}

.icon-amazon-pay:before {
  content: "";
}

.icon-amazon:before {
  content: "";
}

.icon-amilia:before {
  content: "";
}

.icon-android:before {
  content: "";
}

.icon-angellist:before {
  content: "";
}

.icon-angrycreative:before {
  content: "";
}

.icon-angular:before {
  content: "";
}

.icon-app-store-ios:before {
  content: "";
}

.icon-app-store:before {
  content: "";
}

.icon-apper:before {
  content: "";
}

.icon-apple-pay:before {
  content: "";
}

.icon-apple:before {
  content: "";
}

.icon-asymmetrik:before {
  content: "";
}

.icon-audible:before {
  content: "";
}

.icon-autoprefixer:before {
  content: "";
}

.icon-avianex:before {
  content: "";
}

.icon-aviato:before {
  content: "";
}

.icon-aws:before {
  content: "";
}

.icon-bandcamp:before {
  content: "";
}

.icon-behance-square:before {
  content: "";
}

.icon-behance:before {
  content: "";
}

.icon-bimobject:before {
  content: "";
}

.icon-bitbucket:before {
  content: "";
}

.icon-bitcoin:before {
  content: "";
}

.icon-bity:before {
  content: "";
}

.icon-black-tie:before {
  content: "";
}

.icon-blackberry:before {
  content: "";
}

.icon-blogger-b:before {
  content: "";
}

.icon-blogger:before {
  content: "";
}

.icon-bluetooth-b:before {
  content: "";
}

.icon-bluetooth:before {
  content: "";
}

.icon-btc:before {
  content: "";
}

.icon-buromobelexperte:before {
  content: "";
}

.icon-buysellads:before {
  content: "";
}

.icon-cc-amazon-pay:before {
  content: "";
}

.icon-cc-amex:before {
  content: "";
}

.icon-cc-apple-pay:before {
  content: "";
}

.icon-cc-diners-club:before {
  content: "";
}

.icon-cc-discover:before {
  content: "";
}

.icon-cc-jcb:before {
  content: "";
}

.icon-cc-mastercard:before {
  content: "";
}

.icon-cc-paypal:before {
  content: "";
}

.icon-cc-stripe:before {
  content: "";
}

.icon-cc-visa:before {
  content: "";
}

.icon-centercode:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-cloudscale:before {
  content: "";
}

.icon-cloudsmith:before {
  content: "";
}

.icon-cloudversify:before {
  content: "";
}

.icon-codepen:before {
  content: "";
}

.icon-codiepie:before {
  content: "";
}

.icon-connectdevelop:before {
  content: "";
}

.icon-contao:before {
  content: "";
}

.icon-cpanel:before {
  content: "";
}

.icon-creative-commons-by:before {
  content: "";
}

.icon-creative-commons-nc-eu:before {
  content: "";
}

.icon-creative-commons-nc-jp:before {
  content: "";
}

.icon-creative-commons-nc:before {
  content: "";
}

.icon-creative-commons-nd:before {
  content: "";
}

.icon-creative-commons-pd-alt:before {
  content: "";
}

.icon-creative-commons-pd:before {
  content: "";
}

.icon-creative-commons-remix:before {
  content: "";
}

.icon-creative-commons-sa:before {
  content: "";
}

.icon-creative-commons-sampling-plus:before {
  content: "";
}

.icon-creative-commons-sampling:before {
  content: "";
}

.icon-creative-commons-share:before {
  content: "";
}

.icon-creative-commons:before {
  content: "";
}

.icon-css3-alt:before {
  content: "";
}

.icon-css3:before {
  content: "";
}

.icon-cuttlefish:before {
  content: "";
}

.icon-d-and-d:before {
  content: "";
}

.icon-dashcube:before {
  content: "";
}

.icon-delicious:before {
  content: "";
}

.icon-deploydog:before {
  content: "";
}

.icon-deskpro:before {
  content: "";
}

.icon-deviantart:before {
  content: "";
}

.icon-digg:before {
  content: "";
}

.icon-digital-ocean:before {
  content: "";
}

.icon-discord:before {
  content: "";
}

.icon-discourse:before {
  content: "";
}

.icon-dochub:before {
  content: "";
}

.icon-docker:before {
  content: "";
}

.icon-draft2digital:before {
  content: "";
}

.icon-dribbble-square:before {
  content: "";
}

.icon-dribbble:before {
  content: "";
}

.icon-dropbox:before {
  content: "";
}

.icon-drupal:before {
  content: "";
}

.icon-dyalog:before {
  content: "";
}

.icon-earlybirds:before {
  content: "";
}

.icon-ebay:before {
  content: "";
}

.icon-edge:before {
  content: "";
}

.icon-elementor:before {
  content: "";
}

.icon-ello:before {
  content: "";
}

.icon-ember:before {
  content: "";
}

.icon-empire:before {
  content: "";
}

.icon-envira:before {
  content: "";
}

.icon-erlang:before {
  content: "";
}

.icon-ethereum:before {
  content: "";
}

.icon-etsy:before {
  content: "";
}

.icon-expeditedssl:before {
  content: "";
}

.icon-facebook-f:before {
  content: "";
}

.icon-facebook-messenger:before {
  content: "";
}

.icon-facebook-square:before {
  content: "";
}

.icon-facebook1:before {
  content: "";
}

.icon-firefox:before {
  content: "";
}

.icon-first-order-alt:before {
  content: "";
}

.icon-first-order:before {
  content: "";
}

.icon-firstdraft:before {
  content: "";
}

.icon-flickr:before {
  content: "";
}

.icon-flipboard:before {
  content: "";
}

.icon-fly:before {
  content: "";
}

.icon-font-awesome-alt:before {
  content: "";
}

.icon-font-awesome-flag:before {
  content: "";
}

.icon-font-awesome-logo-full2:before {
  content: "";
}

.icon-font-awesome:before {
  content: "";
}

.icon-fonticons-fi:before {
  content: "";
}

.icon-fonticons:before {
  content: "";
}

.icon-fort-awesome-alt:before {
  content: "";
}

.icon-fort-awesome:before {
  content: "";
}

.icon-forumbee:before {
  content: "";
}

.icon-foursquare:before {
  content: "";
}

.icon-free-code-camp:before {
  content: "";
}

.icon-freebsd:before {
  content: "";
}

.icon-fulcrum:before {
  content: "";
}

.icon-galactic-republic:before {
  content: "";
}

.icon-galactic-senate:before {
  content: "";
}

.icon-get-pocket:before {
  content: "";
}

.icon-gg-circle:before {
  content: "";
}

.icon-gg:before {
  content: "";
}

.icon-git-square:before {
  content: "";
}

.icon-git:before {
  content: "";
}

.icon-github-alt:before {
  content: "";
}

.icon-github-square:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-gitkraken:before {
  content: "";
}

.icon-gitlab:before {
  content: "";
}

.icon-gitter:before {
  content: "";
}

.icon-glide-g:before {
  content: "";
}

.icon-glide:before {
  content: "";
}

.icon-gofore:before {
  content: "";
}

.icon-goodreads-g:before {
  content: "";
}

.icon-goodreads:before {
  content: "";
}

.icon-google-drive:before {
  content: "";
}

.icon-google-play:before {
  content: "";
}

.icon-google-plus-g:before {
  content: "";
}

.icon-google-plus-square:before {
  content: "";
}

.icon-google-plus:before {
  content: "";
}

.icon-google-wallet:before {
  content: "";
}

.icon-google:before {
  content: "";
}

.icon-gratipay:before {
  content: "";
}

.icon-grav:before {
  content: "";
}

.icon-gripfire:before {
  content: "";
}

.icon-grunt:before {
  content: "";
}

.icon-gulp:before {
  content: "";
}

.icon-hacker-news-square:before {
  content: "";
}

.icon-hacker-news:before {
  content: "";
}

.icon-hackerrank:before {
  content: "";
}

.icon-hips:before {
  content: "";
}

.icon-hire-a-helper:before {
  content: "";
}

.icon-hooli:before {
  content: "";
}

.icon-hornbill:before {
  content: "";
}

.icon-hotjar:before {
  content: "";
}

.icon-houzz:before {
  content: "";
}

.icon-html5:before {
  content: "";
}

.icon-hubspot:before {
  content: "";
}

.icon-imdb:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-internet-explorer:before {
  content: "";
}

.icon-ioxhost:before {
  content: "";
}

.icon-itunes-note:before {
  content: "";
}

.icon-itunes:before {
  content: "";
}

.icon-java:before {
  content: "";
}

.icon-jedi-order:before {
  content: "";
}

.icon-jenkins:before {
  content: "";
}

.icon-joget:before {
  content: "";
}

.icon-joomla:before {
  content: "";
}

.icon-js-square:before {
  content: "";
}

.icon-js:before {
  content: "";
}

.icon-jsfiddle:before {
  content: "";
}

.icon-kaggle:before {
  content: "";
}

.icon-keybase:before {
  content: "";
}

.icon-keycdn:before {
  content: "";
}

.icon-kickstarter-k:before {
  content: "";
}

.icon-kickstarter:before {
  content: "";
}

.icon-korvue:before {
  content: "";
}

.icon-laravel:before {
  content: "";
}

.icon-lastfm-square:before {
  content: "";
}

.icon-lastfm:before {
  content: "";
}

.icon-leanpub:before {
  content: "";
}

.icon-less:before {
  content: "";
}

.icon-line:before {
  content: "";
}

.icon-linkedin-in:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-linode:before {
  content: "";
}

.icon-linux:before {
  content: "";
}

.icon-lyft:before {
  content: "";
}

.icon-magento:before {
  content: "";
}

.icon-mailchimp:before {
  content: "";
}

.icon-mandalorian:before {
  content: "";
}

.icon-markdown:before {
  content: "";
}

.icon-mastodon:before {
  content: "";
}

.icon-maxcdn:before {
  content: "";
}

.icon-medapps:before {
  content: "";
}

.icon-medium-m:before {
  content: "";
}

.icon-medium:before {
  content: "";
}

.icon-medrt:before {
  content: "";
}

.icon-meetup:before {
  content: "";
}

.icon-megaport:before {
  content: "";
}

.icon-microsoft:before {
  content: "";
}

.icon-mix:before {
  content: "";
}

.icon-mixcloud:before {
  content: "";
}

.icon-mizuni:before {
  content: "";
}

.icon-modx:before {
  content: "";
}

.icon-monero:before {
  content: "";
}

.icon-napster:before {
  content: "";
}

.icon-neos:before {
  content: "";
}

.icon-nimblr:before {
  content: "";
}

.icon-nintendo-switch:before {
  content: "";
}

.icon-node-js:before {
  content: "";
}

.icon-node:before {
  content: "";
}

.icon-npm:before {
  content: "";
}

.icon-ns8:before {
  content: "";
}

.icon-nutritionix:before {
  content: "";
}

.icon-odnoklassniki-square:before {
  content: "";
}

.icon-odnoklassniki:before {
  content: "";
}

.icon-old-republic:before {
  content: "";
}

.icon-opencart:before {
  content: "";
}

.icon-openid:before {
  content: "";
}

.icon-opera:before {
  content: "";
}

.icon-optin-monster:before {
  content: "";
}

.icon-osi:before {
  content: "";
}

.icon-page4:before {
  content: "";
}

.icon-pagelines:before {
  content: "";
}

.icon-palfed:before {
  content: "";
}

.icon-patreon:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-periscope:before {
  content: "";
}

.icon-phabricator:before {
  content: "";
}

.icon-phoenix-framework:before {
  content: "";
}

.icon-phoenix-squadron:before {
  content: "";
}

.icon-php:before {
  content: "";
}

.icon-pied-piper-alt:before {
  content: "";
}

.icon-pied-piper-hat:before {
  content: "";
}

.icon-pied-piper-pp:before {
  content: "";
}

.icon-pied-piper:before {
  content: "";
}

.icon-pinterest-p:before {
  content: "";
}

.icon-pinterest-square:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-playstation:before {
  content: "";
}

.icon-product-hunt:before {
  content: "";
}

.icon-pushed:before {
  content: "";
}

.icon-python:before {
  content: "";
}

.icon-qq:before {
  content: "";
}

.icon-quinscape:before {
  content: "";
}

.icon-quora:before {
  content: "";
}

.icon-r-project:before {
  content: "";
}

.icon-ravelry:before {
  content: "";
}

.icon-react:before {
  content: "";
}

.icon-readme:before {
  content: "";
}

.icon-rebel:before {
  content: "";
}

.icon-red-river:before {
  content: "";
}

.icon-reddit-alien:before {
  content: "";
}

.icon-reddit-square:before {
  content: "";
}

.icon-reddit:before {
  content: "";
}

.icon-rendact:before {
  content: "";
}

.icon-renren:before {
  content: "";
}

.icon-replyd:before {
  content: "";
}

.icon-researchgate:before {
  content: "";
}

.icon-resolving:before {
  content: "";
}

.icon-rev:before {
  content: "";
}

.icon-rocketchat:before {
  content: "";
}

.icon-rockrms:before {
  content: "";
}

.icon-safari:before {
  content: "";
}

.icon-sass:before {
  content: "";
}

.icon-schlix:before {
  content: "";
}

.icon-scribd:before {
  content: "";
}

.icon-searchengin:before {
  content: "";
}

.icon-sellcast:before {
  content: "";
}

.icon-sellsy:before {
  content: "";
}

.icon-servicestack:before {
  content: "";
}

.icon-shirtsinbulk:before {
  content: "";
}

.icon-shopware:before {
  content: "";
}

.icon-simplybuilt:before {
  content: "";
}

.icon-sistrix:before {
  content: "";
}

.icon-sith:before {
  content: "";
}

.icon-skyatlas:before {
  content: "";
}

.icon-skype:before {
  content: "";
}

.icon-slack-hash:before {
  content: "";
}

.icon-slack:before {
  content: "";
}

.icon-slideshare:before {
  content: "";
}

.icon-snapchat-ghost:before {
  content: "";
}

.icon-snapchat-square:before {
  content: "";
}

.icon-snapchat:before {
  content: "";
}

.icon-soundcloud:before {
  content: "";
}

.icon-speakap:before {
  content: "";
}

.icon-spotify:before {
  content: "";
}

.icon-squarespace:before {
  content: "";
}

.icon-stack-exchange:before {
  content: "";
}

.icon-stack-overflow:before {
  content: "";
}

.icon-staylinked:before {
  content: "";
}

.icon-steam-square:before {
  content: "";
}

.icon-steam-symbol:before {
  content: "";
}

.icon-steam:before {
  content: "";
}

.icon-sticker-mule:before {
  content: "";
}

.icon-strava:before {
  content: "";
}

.icon-stripe-s:before {
  content: "";
}

.icon-stripe:before {
  content: "";
}

.icon-studiovinari:before {
  content: "";
}

.icon-stumbleupon-circle:before {
  content: "";
}

.icon-stumbleupon:before {
  content: "";
}

.icon-superpowers:before {
  content: "";
}

.icon-supple:before {
  content: "";
}

.icon-teamspeak:before {
  content: "";
}

.icon-telegram-plane:before {
  content: "";
}

.icon-telegram:before {
  content: "";
}

.icon-tencent-weibo:before {
  content: "";
}

.icon-the-red-yeti:before {
  content: "";
}

.icon-themeco:before {
  content: "";
}

.icon-themeisle:before {
  content: "";
}

.icon-trade-federation:before {
  content: "";
}

.icon-trello:before {
  content: "";
}

.icon-tripadvisor:before {
  content: "";
}

.icon-tumblr-square:before {
  content: "";
}

.icon-tumblr:before {
  content: "";
}

.icon-twitch:before {
  content: "";
}

.icon-twitter-square:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-typo3:before {
  content: "";
}

.icon-uber:before {
  content: "";
}

.icon-uikit:before {
  content: "";
}

.icon-uniregistry:before {
  content: "";
}

.icon-untappd:before {
  content: "";
}

.icon-usb:before {
  content: "";
}

.icon-ussunnah:before {
  content: "";
}

.icon-vaadin:before {
  content: "";
}

.icon-viacoin:before {
  content: "";
}

.icon-viadeo-square:before {
  content: "";
}

.icon-viadeo:before {
  content: "";
}

.icon-viber:before {
  content: "";
}

.icon-vimeo-square:before {
  content: "";
}

.icon-vimeo-v:before {
  content: "";
}

.icon-vimeo:before {
  content: "";
}

.icon-vine:before {
  content: "";
}

.icon-vk:before {
  content: "";
}

.icon-vnv:before {
  content: "";
}

.icon-vuejs:before {
  content: "";
}

.icon-weebly:before {
  content: "";
}

.icon-weibo:before {
  content: "";
}

.icon-weixin:before {
  content: "";
}

.icon-whatsapp-square:before {
  content: "";
}

.icon-whatsapp:before {
  content: "";
}

.icon-whmcs:before {
  content: "";
}

.icon-wikipedia-w:before {
  content: "";
}

.icon-windows:before {
  content: "";
}

.icon-wix:before {
  content: "";
}

.icon-wolf-pack-battalion:before {
  content: "";
}

.icon-wordpress-simple:before {
  content: "";
}

.icon-wordpress:before {
  content: "";
}

.icon-wpbeginner:before {
  content: "";
}

.icon-wpexplorer:before {
  content: "";
}

.icon-wpforms:before {
  content: "";
}

.icon-xbox:before {
  content: "";
}

.icon-xing-square:before {
  content: "";
}

.icon-xing:before {
  content: "";
}

.icon-y-combinator:before {
  content: "";
}

.icon-yahoo:before {
  content: "";
}

.icon-yandex-international:before {
  content: "";
}

.icon-yandex:before {
  content: "";
}

.icon-yelp:before {
  content: "";
}

.icon-yoast:before {
  content: "";
}

.icon-youtube-square:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-zhihu:before {
  content: "";
}

.icon-line-open:before {
  content: "";
}

.icon-line-bag:before {
  content: "";
}

.icon-line-grid-2:before {
  content: "";
}

.icon-line-content-left:before {
  content: "";
}

.icon-line-content-right:before {
  content: "";
}

.icon-line-esc:before {
  content: "";
}

.icon-line-alt:before {
  content: "";
}

.icon-line-marquee-plus:before {
  content: "";
}

.icon-line-marquee-minus:before {
  content: "";
}

.icon-line-marquee:before {
  content: "";
}

.icon-line-square-check:before {
  content: "";
}

.icon-line-paragraph:before {
  content: "";
}

.icon-line-ribbon:before {
  content: "";
}

.icon-line-location-2:before {
  content: "";
}

.icon-line-circle-check:before {
  content: "";
}

.icon-line-circle-cross1:before {
  content: "";
}

.icon-line-reply:before {
  content: "";
}

.icon-line-paper-stack:before {
  content: "";
}

.icon-line-stack-2:before {
  content: "";
}

.icon-line-stack:before {
  content: "";
}

.icon-line-activity:before {
  content: "";
}

.icon-line-air-play:before {
  content: "";
}

.icon-line-alert-circle:before {
  content: "";
}

.icon-line-alert-octagon:before {
  content: "";
}

.icon-line-alert-triangle:before {
  content: "";
}

.icon-line-align-center:before {
  content: "";
}

.icon-line-align-justify:before {
  content: "";
}

.icon-line-align-left:before {
  content: "";
}

.icon-line-align-right:before {
  content: "";
}

.icon-line-anchor:before {
  content: "";
}

.icon-line-aperture:before {
  content: "";
}

.icon-line-archive:before {
  content: "";
}

.icon-line-arrow-down:before {
  content: "";
}

.icon-line-arrow-down-circle:before {
  content: "";
}

.icon-line-arrow-down-left:before {
  content: "";
}

.icon-line-arrow-down-right:before {
  content: "";
}

.icon-line-arrow-left:before {
  content: "";
}

.icon-line-arrow-left-circle:before {
  content: "";
}

.icon-line-arrow-right:before {
  content: "";
}

.icon-line-arrow-right-circle:before {
  content: "";
}

.icon-line-arrow-up:before {
  content: "";
}

.icon-line-arrow-up-circle:before {
  content: "";
}

.icon-line-arrow-up-left:before {
  content: "";
}

.icon-line-arrow-up-right:before {
  content: "";
}

.icon-line-at-sign:before {
  content: "";
}

.icon-line-award:before {
  content: "";
}

.icon-line-bar-graph:before {
  content: "";
}

.icon-line-bar-graph-2:before {
  content: "";
}

.icon-line-battery:before {
  content: "";
}

.icon-line-battery-charging:before {
  content: "";
}

.icon-line-bell:before {
  content: "";
}

.icon-line-bell-off:before {
  content: "";
}

.icon-line-bluetooth:before {
  content: "";
}

.icon-line-bold:before {
  content: "";
}

.icon-line-book:before {
  content: "";
}

.icon-line-book-open:before {
  content: "";
}

.icon-line-bookmark:before {
  content: "";
}

.icon-line-box:before {
  content: "";
}

.icon-line-briefcase:before {
  content: "";
}

.icon-line-calendar:before {
  content: "";
}

.icon-line-camera:before {
  content: "";
}

.icon-line-camera-off:before {
  content: "";
}

.icon-line-cast:before {
  content: "";
}

.icon-line-check:before {
  content: "";
}

.icon-line-check-circle:before {
  content: "";
}

.icon-line-check-square:before {
  content: "";
}

.icon-line-chevron-down:before {
  content: "";
}

.icon-line-chevron-left:before {
  content: "";
}

.icon-line-chevron-right:before {
  content: "";
}

.icon-line-chevron-up:before {
  content: "";
}

.icon-line-chevrons-down:before {
  content: "";
}

.icon-line-chevrons-left:before {
  content: "";
}

.icon-line-chevrons-right:before {
  content: "";
}

.icon-line-chevrons-up:before {
  content: "";
}

.icon-line-chrome:before {
  content: "";
}

.icon-line-record:before,
.icon-line-stop:before {
  content: "";
}

.icon-line-clipboard:before {
  content: "";
}

.icon-line-clock:before {
  content: "";
}

.icon-line-cloud:before {
  content: "";
}

.icon-line-cloud-drizzle:before {
  content: "";
}

.icon-line-cloud-lightning:before {
  content: "";
}

.icon-line-cloud-off:before {
  content: "";
}

.icon-line-cloud-rain:before {
  content: "";
}

.icon-line-cloud-snow:before {
  content: "";
}

.icon-line-code:before {
  content: "";
}

.icon-line-codepen:before {
  content: "";
}

.icon-line-codesandbox:before {
  content: "";
}

.icon-line-coffee:before {
  content: "";
}

.icon-line-columns:before {
  content: "";
}

.icon-line-command:before {
  content: "";
}

.icon-line-compass:before {
  content: "";
}

.icon-line-copy:before {
  content: "";
}

.icon-line-corner-down-left:before {
  content: "";
}

.icon-line-corner-down-right:before {
  content: "";
}

.icon-line-corner-left-down:before {
  content: "";
}

.icon-line-corner-left-up:before {
  content: "";
}

.icon-line-corner-right-down:before {
  content: "";
}

.icon-line-corner-right-up:before {
  content: "";
}

.icon-line-corner-up-left:before {
  content: "";
}

.icon-line-corner-up-right:before {
  content: "";
}

.icon-line-cpu:before {
  content: "";
}

.icon-line-credit-card:before {
  content: "";
}

.icon-line-crop:before {
  content: "";
}

.icon-line-crosshair:before {
  content: "";
}

.icon-line-database:before {
  content: "";
}

.icon-line-delete:before {
  content: "";
}

.icon-line-disc:before {
  content: "";
}

.icon-line-dollar-sign:before {
  content: "";
}

.icon-line-download:before {
  content: "";
}

.icon-line-cloud-download:before {
  content: "";
}

.icon-line-droplet:before {
  content: "";
}

.icon-line-edit:before {
  content: "";
}

.icon-line-edit-2:before {
  content: "";
}

.icon-line-edit-3:before {
  content: "";
}

.icon-line-external-link:before {
  content: "";
}

.icon-line-eye:before {
  content: "";
}

.icon-line-eye-off:before {
  content: "";
}

.icon-line-facebook:before {
  content: "";
}

.icon-line-fast-forward:before {
  content: "";
}

.icon-line-feather:before {
  content: "";
}

.icon-line-figma:before {
  content: "";
}

.icon-line-file:before {
  content: "";
}

.icon-line-file-subtract:before {
  content: "";
}

.icon-line-file-add:before {
  content: "";
}

.icon-line-paper:before {
  content: "";
}

.icon-line-film:before {
  content: "";
}

.icon-line-filter:before {
  content: "";
}

.icon-line-flag:before {
  content: "";
}

.icon-line-folder:before {
  content: "";
}

.icon-line-folder-minus:before {
  content: "";
}

.icon-line-folder-plus:before {
  content: "";
}

.icon-line-framer:before {
  content: "";
}

.icon-line-frown:before {
  content: "";
}

.icon-line-gift:before {
  content: "";
}

.icon-line-git-branch:before {
  content: "";
}

.icon-line-git-commit:before {
  content: "";
}

.icon-line-git-merge:before {
  content: "";
}

.icon-line-git-pull-request:before {
  content: "";
}

.icon-line-github:before {
  content: "";
}

.icon-line-gitlab:before {
  content: "";
}

.icon-line-globe:before {
  content: "";
}

.icon-line-grid:before {
  content: "";
}

.icon-line-hard-drive:before {
  content: "";
}

.icon-line-hash:before {
  content: "";
}

.icon-line-headphones:before {
  content: "";
}

.icon-line-heart:before {
  content: "";
}

.icon-line-help-circle:before {
  content: "";
}

.icon-line-hexagon:before {
  content: "";
}

.icon-line-home:before {
  content: "";
}

.icon-line-image:before {
  content: "";
}

.icon-line-inbox:before {
  content: "";
}

.icon-line-info:before {
  content: "";
}

.icon-line-instagram:before {
  content: "";
}

.icon-line-italic:before {
  content: "";
}

.icon-line-key:before {
  content: "";
}

.icon-line-layers:before {
  content: "";
}

.icon-line-layout:before {
  content: "";
}

.icon-line-help:before {
  content: "";
}

.icon-line-link:before {
  content: "";
}

.icon-line-link-2:before {
  content: "";
}

.icon-line-linkedin:before {
  content: "";
}

.icon-line-list:before {
  content: "";
}

.icon-line-loader:before {
  content: "";
}

.icon-line-lock:before {
  content: "";
}

.icon-line-log-in:before {
  content: "";
}

.icon-line-log-out:before {
  content: "";
}

.icon-line-mail:before {
  content: "";
}

.icon-line-map:before {
  content: "";
}

.icon-line-map-pin:before {
  content: "";
}

.icon-line-expand:before {
  content: "";
}

.icon-line-maximize:before {
  content: "";
}

.icon-line-meh:before {
  content: "";
}

.icon-line-menu:before {
  content: "";
}

.icon-line-message-circle:before {
  content: "";
}

.icon-line-speech-bubble:before {
  content: "";
}

.icon-line-microphone:before {
  content: "";
}

.icon-line-microphone-off:before {
  content: "";
}

.icon-line-contract:before {
  content: "";
}

.icon-line-minimize:before {
  content: "";
}

.icon-line-minus:before {
  content: "";
}

.icon-line-circle-minus:before {
  content: "";
}

.icon-line-square-minus:before {
  content: "";
}

.icon-line-monitor:before {
  content: "";
}

.icon-line-moon:before {
  content: "";
}

.icon-line-more-horizontal:before,
.icon-line-ellipsis:before {
  content: "";
}

.icon-line-more-vertical:before {
  content: "";
}

.icon-line-mouse-pointer:before {
  content: "";
}

.icon-line-move:before {
  content: "";
}

.icon-line-music:before {
  content: "";
}

.icon-line-location:before {
  content: "";
}

.icon-line-navigation:before {
  content: "";
}

.icon-line-octagon:before {
  content: "";
}

.icon-line-package:before {
  content: "";
}

.icon-line-paper-clip:before {
  content: "";
}

.icon-line-pause:before {
  content: "";
}

.icon-line-pause-circle:before {
  content: "";
}

.icon-line-pen-tool:before {
  content: "";
}

.icon-line-percent:before {
  content: "";
}

.icon-line-phone:before {
  content: "";
}

.icon-line-phone-call:before {
  content: "";
}

.icon-line-phone-forwarded:before {
  content: "";
}

.icon-line-phone-incoming:before {
  content: "";
}

.icon-line-phone-missed:before {
  content: "";
}

.icon-line-phone-off:before {
  content: "";
}

.icon-line-phone-outgoing:before {
  content: "";
}

.icon-line-pie-graph:before {
  content: "";
}

.icon-line-play:before {
  content: "";
}

.icon-line-play-circle:before {
  content: "";
}

.icon-line-plus:before {
  content: "";
}

.icon-line-circle-plus:before {
  content: "";
}

.icon-line-square-plus:before {
  content: "";
}

.icon-line-pocket:before {
  content: "";
}

.icon-line-power:before {
  content: "";
}

.icon-line-printer:before {
  content: "";
}

.icon-line-signal:before {
  content: "";
}

.icon-line-refresh-ccw:before {
  content: "";
}

.icon-line-refresh-cw:before {
  content: "";
}

.icon-line-repeat:before {
  content: "";
}

.icon-line-rewind:before {
  content: "";
}

.icon-line-reload:before {
  content: "";
}

.icon-line-rotate-cw:before {
  content: "";
}

.icon-line-rss:before {
  content: "";
}

.icon-line-save:before {
  content: "";
}

.icon-line-scissors:before {
  content: "";
}

.icon-line-search:before {
  content: "";
}

.icon-line-send:before {
  content: "";
}

.icon-line-server:before {
  content: "";
}

.icon-line-cog:before {
  content: "";
}

.icon-line-outbox:before {
  content: "";
}

.icon-line-share:before {
  content: "";
}

.icon-line-shield:before {
  content: "";
}

.icon-line-shield-off:before {
  content: "";
}

.icon-line-shopping-bag:before {
  content: "";
}

.icon-line-shopping-cart:before {
  content: "";
}

.icon-line-shuffle:before {
  content: "";
}

.icon-line-sidebar:before {
  content: "";
}

.icon-line-skip-back:before {
  content: "";
}

.icon-line-skip-forward:before {
  content: "";
}

.icon-line-slack:before {
  content: "";
}

.icon-line-ban:before {
  content: "";
}

.icon-line-sliders:before {
  content: "";
}

.icon-line-smartphone:before {
  content: "";
}

.icon-line-smile:before {
  content: "";
}

.icon-line-speaker:before {
  content: "";
}

.icon-line-square:before {
  content: "";
}

.icon-line-star:before {
  content: "";
}

.icon-line-stop-circle:before {
  content: "";
}

.icon-line-sun:before {
  content: "";
}

.icon-line-sunrise:before {
  content: "";
}

.icon-line-sunset:before {
  content: "";
}

.icon-line-tablet:before {
  content: "";
}

.icon-line-tag:before {
  content: "";
}

.icon-line-target:before {
  content: "";
}

.icon-line-terminal:before {
  content: "";
}

.icon-line-thermometer:before {
  content: "";
}

.icon-line-thumbs-down:before {
  content: "";
}

.icon-line-thumbs-up:before {
  content: "";
}

.icon-line-toggle:before {
  content: "";
}

.icon-line-toggle-right:before {
  content: "";
}

.icon-line-tool:before {
  content: "";
}

.icon-line-trash:before {
  content: "";
}

.icon-line-trash-2:before {
  content: "";
}

.icon-line-trello:before {
  content: "";
}

.icon-line-trending-down:before {
  content: "";
}

.icon-line-trending-up:before {
  content: "";
}

.icon-line-triangle:before {
  content: "";
}

.icon-line-truck:before {
  content: "";
}

.icon-line-tv:before {
  content: "";
}

.icon-line-twitch:before {
  content: "";
}

.icon-line-twitter:before {
  content: "";
}

.icon-line-type:before {
  content: "";
}

.icon-line-umbrella:before {
  content: "";
}

.icon-line-underline:before {
  content: "";
}

.icon-line-unlock:before {
  content: "";
}

.icon-line-upload:before {
  content: "";
}

.icon-line-cloud-upload:before {
  content: "";
}

.icon-line-head:before {
  content: "";
}

.icon-line-user-check:before {
  content: "";
}

.icon-line-user-minus:before {
  content: "";
}

.icon-line-user-plus:before {
  content: "";
}

.icon-line-user-cross:before {
  content: "";
}

.icon-line-users:before {
  content: "";
}

.icon-line-video:before {
  content: "";
}

.icon-line-video-off:before {
  content: "";
}

.icon-line-voicemail:before {
  content: "";
}

.icon-line-volume-off:before {
  content: "";
}

.icon-line-volume-1:before {
  content: "";
}

.icon-line-volume:before {
  content: "";
}

.icon-line-mute:before {
  content: "";
}

.icon-line-watch:before {
  content: "";
}

.icon-line-wifi:before {
  content: "";
}

.icon-line-wifi-off:before {
  content: "";
}

.icon-line-wind:before {
  content: "";
}

.icon-line-cross:before {
  content: "";
}

.icon-line-circle-cross:before {
  content: "";
}

.icon-line-cross-octagon:before {
  content: "";
}

.icon-line-square-cross:before {
  content: "";
}

.icon-line-youtube:before {
  content: "";
}

.icon-line-zap:before {
  content: "";
}

.icon-line-zap-off:before {
  content: "";
}

.icon-line-zoom-in:before {
  content: "";
}

.icon-line-zoom-out:before {
  content: "";
}

.icon-line2-user-female:before {
  content: "";
}

.icon-line2-user-follow:before {
  content: "";
}

.icon-line2-user-following:before {
  content: "";
}

.icon-line2-user-unfollow:before {
  content: "";
}

.icon-line2-trophy:before {
  content: "";
}

.icon-line2-screen-smartphone:before {
  content: "";
}

.icon-line2-screen-desktop:before {
  content: "";
}

.icon-line2-plane:before {
  content: "";
}

.icon-line2-notebook:before {
  content: "";
}

.icon-line2-moustache:before {
  content: "";
}

.icon-line2-mouse:before {
  content: "";
}

.icon-line2-magnet:before {
  content: "";
}

.icon-line2-energy:before {
  content: "";
}

.icon-line2-emoticon-smile:before {
  content: "";
}

.icon-line2-disc:before {
  content: "";
}

.icon-line2-cursor-move:before {
  content: "";
}

.icon-line2-crop:before {
  content: "";
}

.icon-line2-credit-card:before {
  content: "";
}

.icon-line2-chemistry:before {
  content: "";
}

.icon-line2-user:before {
  content: "";
}

.icon-line2-speedometer:before {
  content: "";
}

.icon-line2-social-youtube:before {
  content: "";
}

.icon-line2-social-twitter:before {
  content: "";
}

.icon-line2-social-tumblr:before {
  content: "";
}

.icon-line2-social-facebook:before {
  content: "";
}

.icon-line2-social-dropbox:before {
  content: "";
}

.icon-line2-social-dribbble:before {
  content: "";
}

.icon-line2-shield:before {
  content: "";
}

.icon-line2-screen-tablet:before {
  content: "";
}

.icon-line2-magic-wand:before {
  content: "";
}

.icon-line2-hourglass:before {
  content: "";
}

.icon-line2-graduation:before {
  content: "";
}

.icon-line2-ghost:before {
  content: "";
}

.icon-line2-game-controller:before {
  content: "";
}

.icon-line2-fire:before {
  content: "";
}

.icon-line2-eyeglasses:before {
  content: "";
}

.icon-line2-envelope-open:before {
  content: "";
}

.icon-line2-envelope-letter:before {
  content: "";
}

.icon-line2-bell:before {
  content: "";
}

.icon-line2-badge:before {
  content: "";
}

.icon-line2-anchor:before {
  content: "";
}

.icon-line2-wallet:before {
  content: "";
}

.icon-line2-vector:before {
  content: "";
}

.icon-line2-speech:before {
  content: "";
}

.icon-line2-puzzle:before {
  content: "";
}

.icon-line2-printer:before {
  content: "";
}

.icon-line2-present:before {
  content: "";
}

.icon-line2-playlist:before {
  content: "";
}

.icon-line2-pin:before {
  content: "";
}

.icon-line2-picture:before {
  content: "";
}

.icon-line2-map:before {
  content: "";
}

.icon-line2-layers:before {
  content: "";
}

.icon-line2-handbag:before {
  content: "";
}

.icon-line2-globe-alt:before {
  content: "";
}

.icon-line2-globe:before {
  content: "";
}

.icon-line2-frame:before {
  content: "";
}

.icon-line2-folder-alt:before {
  content: "";
}

.icon-line2-film:before {
  content: "";
}

.icon-line2-feed:before {
  content: "";
}

.icon-line2-earphones-alt:before {
  content: "";
}

.icon-line2-earphones:before {
  content: "";
}

.icon-line2-drop:before {
  content: "";
}

.icon-line2-drawer:before {
  content: "";
}

.icon-line2-docs:before {
  content: "";
}

.icon-line2-directions:before {
  content: "";
}

.icon-line2-direction:before {
  content: "";
}

.icon-line2-diamond:before {
  content: "";
}

.icon-line2-cup:before {
  content: "";
}

.icon-line2-compass:before {
  content: "";
}

.icon-line2-call-out:before {
  content: "";
}

.icon-line2-call-in:before {
  content: "";
}

.icon-line2-call-end:before {
  content: "";
}

.icon-line2-calculator:before {
  content: "";
}

.icon-line2-bubbles:before {
  content: "";
}

.icon-line2-briefcase:before {
  content: "";
}

.icon-line2-book-open:before {
  content: "";
}

.icon-line2-basket-loaded:before {
  content: "";
}

.icon-line2-basket:before {
  content: "";
}

.icon-line2-bag:before {
  content: "";
}

.icon-line2-action-undo:before {
  content: "";
}

.icon-line2-action-redo:before {
  content: "";
}

.icon-line2-wrench:before {
  content: "";
}

.icon-line2-umbrella:before {
  content: "";
}

.icon-line2-trash:before {
  content: "";
}

.icon-line2-tag:before {
  content: "";
}

.icon-line2-support:before {
  content: "";
}

.icon-line2-size-fullscreen:before {
  content: "";
}

.icon-line2-size-actual:before {
  content: "";
}

.icon-line2-shuffle:before {
  content: "";
}

.icon-line2-share-alt:before {
  content: "";
}

.icon-line2-share:before {
  content: "";
}

.icon-line2-rocket:before {
  content: "";
}

.icon-line2-question:before {
  content: "";
}

.icon-line2-pie-chart:before {
  content: "";
}

.icon-line2-pencil:before {
  content: "";
}

.icon-line2-note:before {
  content: "";
}

.icon-line2-music-tone-alt:before {
  content: "";
}

.icon-line2-music-tone:before {
  content: "";
}

.icon-line2-microphone:before {
  content: "";
}

.icon-line2-loop:before {
  content: "";
}

.icon-line2-logout:before {
  content: "";
}

.icon-line2-login:before {
  content: "";
}

.icon-line2-list:before {
  content: "";
}

.icon-line2-like:before {
  content: "";
}

.icon-line2-home:before {
  content: "";
}

.icon-line2-grid:before {
  content: "";
}

.icon-line2-graph:before {
  content: "";
}

.icon-line2-equalizer:before {
  content: "";
}

.icon-line2-dislike:before {
  content: "";
}

.icon-line2-cursor:before {
  content: "";
}

.icon-line2-control-start:before {
  content: "";
}

.icon-line2-control-rewind:before {
  content: "";
}

.icon-line2-control-play:before {
  content: "";
}

.icon-line2-control-pause:before {
  content: "";
}

.icon-line2-control-forward:before {
  content: "";
}

.icon-line2-control-end:before {
  content: "";
}

.icon-line2-calendar:before {
  content: "";
}

.icon-line2-bulb:before {
  content: "";
}

.icon-line2-bar-chart:before {
  content: "";
}

.icon-line2-arrow-up:before {
  content: "";
}

.icon-line2-arrow-right:before {
  content: "";
}

.icon-line2-arrow-left:before {
  content: "";
}

.icon-line2-arrow-down:before {
  content: "";
}

.icon-line2-ban:before {
  content: "";
}

.icon-line2-bubble:before {
  content: "";
}

.icon-line2-camcorder:before {
  content: "";
}

.icon-line2-camera:before {
  content: "";
}

.icon-line2-check:before {
  content: "";
}

.icon-line2-clock:before {
  content: "";
}

.icon-line2-close:before {
  content: "";
}

.icon-line2-cloud-download:before {
  content: "";
}

.icon-line2-cloud-upload:before {
  content: "";
}

.icon-line2-doc:before {
  content: "";
}

.icon-line2-envelope:before {
  content: "";
}

.icon-line2-eye:before {
  content: "";
}

.icon-line2-flag:before {
  content: "";
}

.icon-line2-folder:before {
  content: "";
}

.icon-line2-heart:before {
  content: "";
}

.icon-line2-info:before {
  content: "";
}

.icon-line2-key:before {
  content: "";
}

.icon-line2-link:before {
  content: "";
}

.icon-line2-lock:before {
  content: "";
}

.icon-line2-lock-open:before {
  content: "";
}

.icon-line2-magnifier:before {
  content: "";
}

.icon-line2-magnifier-add:before {
  content: "";
}

.icon-line2-magnifier-remove:before {
  content: "";
}

.icon-line2-paper-clip:before {
  content: "";
}

.icon-line2-paper-plane:before {
  content: "";
}

.icon-line2-plus:before {
  content: "";
}

.icon-line2-pointer:before {
  content: "";
}

.icon-line2-power:before {
  content: "";
}

.icon-line2-refresh:before {
  content: "";
}

.icon-line2-reload:before {
  content: "";
}

.icon-line2-settings:before {
  content: "";
}

.icon-line2-star:before {
  content: "";
}

.icon-line2-symbol-female:before {
  content: "";
}

.icon-line2-symbol-male:before {
  content: "";
}

.icon-line2-target:before {
  content: "";
}

.icon-line2-volume-1:before {
  content: "";
}

.icon-line2-volume-2:before {
  content: "";
}

.icon-line2-volume-off:before {
  content: "";
}

.icon-line2-users:before {
  content: "";
}

.icon-deezer:before {
  content: "";
}

.icon-edge-legacy:before {
  content: "";
}

.icon-google-pay:before {
  content: "";
}

.icon-google-plus:before {
  content: "";
}

.icon-rust:before {
  content: "";
}

.icon-tiktok:before {
  content: "";
}

.icon-tripadvisor:before {
  content: "";
}

.icon-unsplash:before {
  content: "";
}

.icon-yahoo:before {
  content: "";
}

.icon-box-tissue:before {
  content: "";
}

.icon-hand-holding-medical:before {
  content: "";
}

.icon-hand-holding-water:before {
  content: "";
}

.icon-hand-sparkles:before {
  content: "";
}

.icon-hands-wash:before {
  content: "";
}

.icon-handshake-alt-slash:before {
  content: "";
}

.icon-handshake-slash:before {
  content: "";
}

.icon-head-side-cough-slash:before {
  content: "";
}

.icon-head-side-cough:before {
  content: "";
}

.icon-head-side-mask:before {
  content: "";
}

.icon-head-side-virus:before {
  content: "";
}

.icon-house-user:before {
  content: "";
}

.icon-laptop-house:before {
  content: "";
}

.icon-lungs-virus:before {
  content: "";
}

.icon-people-arrows:before {
  content: "";
}

.icon-plane-slash:before {
  content: "";
}

.icon-pump-medical:before {
  content: "";
}

.icon-pump-soap:before {
  content: "";
}

.icon-shield-virus:before {
  content: "";
}

.icon-sink:before {
  content: "";
}

.icon-socks:before {
  content: "";
}

.icon-stopwatch-20:before {
  content: "";
}

.icon-store-alt-slash:before {
  content: "";
}

.icon-store-slash:before {
  content: "";
}

.icon-toilet-paper-slash:before {
  content: "";
}

.icon-users-slash:before {
  content: "";
}

.icon-virus-slash:before {
  content: "";
}

.icon-virus:before {
  content: "";
}

.icon-viruses:before {
  content: "";
}

.icon-bandcamp:before {
  content: "";
}

.icon-bacteria:before {
  content: "";
}

.icon-bacterium:before {
  content: "";
}